import React, { useEffect, useState } from "react";
import withClickOutside from "./withClickOutside";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faMagnifyingGlass,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import TooltipButton from "../TooltipButton/TooltipButton";

const SelectComponent = React.forwardRef(
  (
    {
      options,
      placeholder = "",
      onChange,
      selectedKey,
      open,
      setOpen,
      title,
      name,
      onFormChange,
      onSelectAllChange,
      selectedValue,
      onClear,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(placeholder);
    const [selectAllVall, setSelectAllVal] = useState(false);

    const [selectedList, setSelectedOptionList] = useState(selectedValue);

    useEffect(() => {
      if (selectedKey && options) {
        setInputValue(options?.find((o) => o === selectedKey));
      }
    }, [selectedKey, options]);

    useEffect(() => {
      if (!open && options?.findIndex((o) => o === inputValue) === -1) {
        if (!inputValue) {
          onChange("");
        } else {
          if (selectedKey) {
            setInputValue(options?.find((o) => o === selectedKey));
          } else {
            setInputValue("");
          }
        }
      }
    }, [open, options, selectedKey, inputValue, onChange]);

    const onInputChange = (e) => {
      setInputValue(e.target.value);
    };

    const onInputClick = () => {
      setOpen((prevValue) => !prevValue);
      setInputValue("");
    };

    const onSelectAll = (option) => {

      let checked = option.target.checked;
      setSelectAllVal(checked);

      if(checked) {
        onSelectAllChange(options, name);

      } else {
        onSelectAllChange([], name);
      }

    };

    const onOptionSelected = (option) => {
   
      onFormChange(option, name);
    };

    const clearDropdown = () => {
      setInputValue("");
      onChange("");
    };

    useEffect(() => {
      setSelectedOptionList(selectedValue);
    }, [selectedValue]);

    function getHighlightedText(text, highlight) {

      // Split on highlight term and include term into parts, ignore case
      if (!highlight) return text;
      
      let startIndex = (text.toLowerCase().indexOf(highlight.toLowerCase()));
    
      if (text.substr(startIndex, highlight.length).toLowerCase() === highlight.toLowerCase()) {
        return(
          <div>
            <span>{text.substr(0, startIndex)}</span>  
            <span className="fw-bold">{text.substr(startIndex, highlight.length)}</span>
            <span>{text.substr(startIndex + highlight.length, text.length)}</span>
          </div>
        )
      }
      else{
        return(
          <div>{text}</div>
        )
      }
    }

    var filtering = () => {
      const searchTerm = inputValue.toLowerCase();
      let arr = [];

      if (searchTerm) {
        arr = options?.filter((item) => {
          const v = item.toLowerCase();

          return v.includes(searchTerm);
        });
      } else {
        arr = options;
      }

      return arr.length >= 1 ? (
        arr.map((opt, idx) => {
          return IndividualItem(opt, idx);
        })
      ) : (
        <li className="list-group-item ">No matches found</li>
      );
    };

    var IndividualItem = (opt, idx) => {
      return (
        <li
          key={idx}
          onClick={() => onOptionSelected(opt)}
          value={opt}
          className="list-group-item list-group-item-action"
        >
          <Form.Check
            type="checkbox"
            label={getHighlightedText(opt, inputValue)}
            onChange={() => onOptionSelected(opt)}
            checked={selectedList?.includes(opt)}
          />
        </li>
      );
    };

    return (
      <div className="dropdown-container" ref={ref}>
        <div
          className={`border rounded ${
            selectedList?.length >= 1 ? "selectedBorderList" : ""
          } `}
        >
          <div
            className="selectedValueContainer flex-box-auto"
            onClick={onInputClick}
          >
            <div className="d-flex align-items-center w-75">
              {selectedList?.length > 0 ? (
                <span className="box">{selectedList[0]}</span>
              ) : (
                <span className="box">{title}</span>
              )}
              {selectedList?.length > 1 && (
                <TooltipButton tooltipMessage={selectedList}>
                  <span className="badge bg-secondary">
                    +{selectedList?.length - 1}
                  </span>
                </TooltipButton>
              )}
            </div>
            <div className="flex-box-auto">
              {selectedList?.length > 0 && (
                <>
                  <span
                    onClick={() => onClear(name)}
                    className="fs-12 text-danger cursor-pointer ms-2"
                  >
                    <FontAwesomeIcon icon={faXmark} /> Clear
                  </span>
                </>
              )}
              {open ? (
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className="mx-2 text-muted"
                  size="sm"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="mx-2 text-muted"
                  size="sm"
                />
              )}
            </div>
          </div>
          {open ? (
            <div
              className={`input-container dropdownStyle  bg-white mt-1 shadow rounded card-body ${
                open ? "visible" : ""
              }`}
            >
              <InputGroup className="mb-2 border-bottom">
                <InputGroup.Text className="bg-white border-0 px-1">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    size="sm"
                    className="text-muted"
                  />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={inputValue}
                  placeholder={placeholder}
                  onChange={(e) => onInputChange(e)}
                  className="border-0 customInput ps-2"
                />
                {selectedKey || inputValue ? (
                  <InputGroup.Text
                    className="bg-white border-0 text-danger  cursor-pointer"
                    onClick={clearDropdown}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </InputGroup.Text>
                ) : null}
              </InputGroup>

              <div className="d-flex justify-content-start">
                <Form.Check
                  type="checkbox"
                  id="Select All"
                  className="mb-2"
                  label="Select / Deselect All"
                  onChange={(eve) => onSelectAll(eve)}
                  checked={options.length === selectedList.length}
                />
              </div>

              <div className="dropdownOverflow">
                <ul className="list-group list-group-flush">{filtering()}</ul>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);

export default withClickOutside(SelectComponent);
