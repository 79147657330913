import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Filter, { handleGAEvent } from "../../components/Filter/Filter";
import AxiosService from "../../utils/AxiosService";
import { setDateRange, setTableLoader } from "../../Redux/Action/filter.action";

import TableGridLayout from "../../components/TableGridLayout/TableGridLayout";
import ToggleMultiline from "../../components/graphs/D3/MultiLine/ToggleMultiline";

const TimesToPublication = () => {
  const {
    searchValue,
    dateRange,
    status,
    currentPage,
    rejectingJournal,
    acceptingJournal,
    publishers,
    accessType,
    isPageReload,
  } = useSelector((state) => state.allFilters);

  const dispatch = useDispatch();
  const axiosService = new AxiosService();
  const [loading, setLoading] = useState({
    isApplyFilter: false,
    isClearFilter: false,
    isGo: false,
  });
  const [graphLoader, setGraphLoader] = useState(false);
  const [reqBody, setReqBody] = useState({});

  const [chartData, setChartData] = useState([]);
  const [fullChartData, setFullChartData] = useState({});

  const [activeBtn, setActiveBtn] = useState("Publication");

  const [gridData, setGridData] = useState({});
  const [sort, setSort] = useState({
    field: "totalPublication",
    order: "desc",
  });
  const [start, setStart] = useState(0);
  const offset = 10;

  const getAllRecords = async () => {
    let startDate = "";
    let endDate = "";
    if (
      dateRange.rejectionStartDate.length > 10 ||
      dateRange.rejectionEndDate.length > 10
    ) {
      startDate = dateRange.rejectionStartDate.slice(0, 10);
      endDate = dateRange.rejectionEndDate.slice(0, 10);
    } else {
      startDate = dateRange.rejectionStartDate;
      endDate = dateRange.rejectionEndDate;
    }
    dispatch(setTableLoader(true));
    setGraphLoader(true);
    const start = (currentPage - 1) * offset;
    setStart(start);
    const reqBody = {
      vendor: "",
      start_date: startDate,
      end_date: endDate,
      status: status,
      searchTerm: searchValue,
      filter: {
        s_journal: rejectingJournal,
        p_journal: acceptingJournal,
        p_publisher: publishers,
      },
      sort: {
        field: sort.field,
        order: sort.order,
      },
    };
    if (accessType) {
      reqBody["filter"].oa = true;
    }
    axiosService
      .getPublicationTimeChart(start, offset, reqBody)
      .then((response) => {
        if (response.data) {
          setGraphLoader(false);
          setLoading({
            ...loading,
            isApplyFilter: false,
            isClearFilter: false,
            isGo: false,
          });
          setFullChartData(response.data);

          if (activeBtn === "Publication") {
            setChartData(response.data && response.data.publicationCount);
          } else if (activeBtn === "Citation") {
            setChartData(response.data && response.data.citationCount);
          }
        }
      })
      .catch((error) => {
        setGraphLoader(false);
        setChartData({});
      });
    axiosService
      .getPublicationTimeGrid(start, offset, reqBody)
      .then((response) => {
        if (response?.data) {
          dispatch(setTableLoader(false));
          setLoading({
            ...loading,
            isApplyFilter: false,
            isClearFilter: false,
            isGo: false,
          });
          setGridData(response?.data);
        }
      })
      .catch((error) => {
        dispatch(setTableLoader(false));
        setGridData({});
      });
    setReqBody(reqBody);
    handleGAEvent(loading, reqBody);
  };

  useEffect(() => {
    if (dateRange.rejectionStartDate) {
      getAllRecords();
    }
  }, [currentPage, sort, isPageReload]);

  useEffect(() => {
    if (
      (loading.isApplyFilter || loading.isGo) &&
      dateRange.rejectionStartDate
    ) {
      setSort({
        field: "totalPublication",
        order: "desc",
      });
    }
  }, [loading.isApplyFilter, loading.isGo]);

  useEffect(() => {
    if (activeBtn === "Citation") {
      setActiveBtn("Publication");
    }

    setSort({
      field: "totalPublication",
      order: "desc",
    });
  }, [loading.isClearFilter]);

  const getCitationChart = () =>{
    setGraphLoader(true);

    setChartData(fullChartData.citationCount);

    setTimeout(() => {
      setGraphLoader(false);
    }, 500);
  }

  const getPublisherChart = () => {
    setGraphLoader(true);

    setChartData(fullChartData.publicationCount);

    setTimeout(() => {
      setGraphLoader(false);
    }, 500);
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Filter
        loading={loading}
        setLoading={setLoading}
        getAllRecords={getAllRecords}
      />
   

      <ToggleMultiline
        data={chartData}
        activeBtn={activeBtn}
        setActiveBtn={setActiveBtn}
        getCitationChart={getCitationChart}
        getPublisherChart={getPublisherChart}
        loader={graphLoader}
      />

      {Object.keys(gridData).length ? (
        <TableGridLayout
          data={gridData}
          start={start}
          setStart={setStart}
          offset={offset}
          gridTitle={"Journals"}
          getAllRecords={getAllRecords}
          sort={sort}
          setSort={setSort}
          reqBody={reqBody}
          gridType="publicationTimeGrid"
        />
      ) : null}
    </div>
  );
};

export default TimesToPublication;
