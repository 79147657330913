import React, {useState, useEffect} from 'react';
import { Row, Col } from 'react-bootstrap';
import TooltipButton from '../../../TooltipButton/TooltipButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faRefresh } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga4';
import { toTitleCase } from '../../../Filter/Filter';
import { SpinnerLoader } from '../../../Loader/Loader';
import NoDataFound from '../../../NotFound/NoDataFound';
import { acceptingVsSubjectCategoriesMessage, rejectingVsAcceptingJournalMessage } from '../../../TooltipButton/TooltipMessage';
import Treemap from './Treemap';

function ToggleTreemap({ data,loading, loader, setLoader, hide=false, title, graphType }){

    const [chartData, setChartData] = useState({});
    const [activeBtn, setActiveBtn] = useState("Publication");

    function onResetChart() {
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
        }, 500);
    }

    useEffect(() => {
        if(activeBtn === "Publication"){
            setChartData(data.publicationCount)
        }
        else{
            setChartData(data.citationCount)
        }
    }, [data])


    useEffect(()=>{
        if(activeBtn==="Citation"){
            setActiveBtn("Publication");
            setChartData(data.publicationCount);
            setLoader(true);
            setTimeout(() => {
                setLoader(false);
            }, 500);
        }
    }, [loading?.isClearFilter])

    return(
        <div className="my-3 card card-body toggleBubbleStyle">
            <Row>
                <Col lg={7} md={12}>
                <h5>
                    {title}
                    <TooltipButton
                        tooltipMessage={graphType ==="categoriesGraph"? acceptingVsSubjectCategoriesMessage : rejectingVsAcceptingJournalMessage}
                        placement="right"
                        type={"string"}
                        classes={"TooltipDiv"}
                    >
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        size="sm"
                        className="fs-14 mx-1 text-secondary me-3"
                    />
                    </TooltipButton>
                    <button
                        className="btn btnDefault btn-info"
                        onClick={onResetChart}
                    >
                    <FontAwesomeIcon
                        icon={faRefresh}
                        className="me-1"
                    ></FontAwesomeIcon>
                    Reset graph
                    </button>
                </h5>
                </Col>
                <Col lg={5} md={12}>
                <div className="d-flex justify-content-end align-items-center">
                    <span className="me-3">By</span>
                    <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic mixed styles example"
                    >
                    <button
                        type="button"
                        onClick={() => {
                            ReactGA.event({
                                category: toTitleCase(title),
                                action: `Publication Count Clicked`
                            });
                            setActiveBtn("Publication");
                            setChartData(data.publicationCount)
                            setLoader(true);
                            setTimeout(() => {
                                setLoader(false);
                            }, 500);
                        }}
                        disabled={loader || !data}
                        className={`btn ${
                        activeBtn === "Publication"
                            ? "btnDefault btn-info"
                            : "border text-muted"
                        }`}
                    >
                        Publication Count
                    </button>

                    <button
                        type="button"
                        onClick={() => {
                            ReactGA.event({
                                category: toTitleCase(title),
                                action: `Citation Count Clicked`
                            });
                            setActiveBtn("Citation");
                            setChartData(data.citationCount)
                            setLoader(true);
                            setTimeout(() => {
                                setLoader(false);
                            }, 500);
                        }}
                        disabled={loader || !data}
                        className={`btn ${
                        activeBtn === "Citation"
                            ? "btnDefault btn-info"
                            : "border text-muted "
                        }`}
                    >
                        Citation Count
                    </button>
                    </div>
                </div>
                </Col>
            </Row>
            {loader ? (
                <div className="loaderSection">
                <SpinnerLoader size="lg" />
                </div>
            ) : chartData ? (
                <Treemap data={chartData} graphType={graphType} />
            ) : (
                <NoDataFound />
            )}
        </div>
    )
}

export default ToggleTreemap;