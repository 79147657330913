import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { InputGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { handleFormChange, setCurrentPage } from '../../Redux/Action/filter.action';
import { ButtonLoader } from '../Loader/Loader';
import classNames from 'classnames';
import styles from './SearchFilter.module.scss';

function SearchFilter({loading, setLoading, current, setCurrent, inputSearchValue, applyModalSearch}){

    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");

    const handleApplyFilter =async(e) => {
        e.preventDefault()
        setLoading({...loading, isApplyFilter: true})
        applyModalSearch(searchValue)
    }

    const resetFilter =(e) => {
        e.preventDefault()
        setSearchValue("");
        setLoading({...loading, isClearFilter:true})
        applyModalSearch("")
    }

    useEffect(() => {
        setSearchValue(inputSearchValue);
    }, [inputSearchValue])

    const handleKeyPress = e => {
        if (e.key === "Enter") {
            handleApplyFilter(e);
        }
      };

    return(
        <Row className='mt-4'>
            <Col md={8} xs={12} className='mb-2'>
                <label className="mb-1 fs-14">Search</label>
                <InputGroup className={classNames(styles.searchStyle)}>
                    <InputGroup.Text className='bg-white pe-0'><FontAwesomeIcon icon={faMagnifyingGlass} size='sm' className='text-muted' /></InputGroup.Text>
                    <Form.Control
                        placeholder="Search by Title / DOI / Journal / Manuscript ID / Decision type"
                        value={searchValue}
                        onChange={(e)=> setSearchValue(e.target.value)}
                        onKeyUp={handleKeyPress}
                        name="searchValue"
                        className={classNames('border-start-0 border-end-0', styles.customInputStyle)}
                    />
                <InputGroup.Text className='bg-white border-start-0 text-danger cursor-pointer' onClick={() => setSearchValue("")}>{searchValue?.trim().length >0 &&<span className='fs-12'><FontAwesomeIcon icon={faXmark} /> Clear</span>}</InputGroup.Text>
                </InputGroup>
            </Col>
            <Col md={2} xs={6} className=' d-flex align-items-end mb-2'>
                {loading.isApplyFilter ? <ButtonLoader classes={'btn btn-info btnDefault w-100'} /> :<button className='btn btn-info btnDefault w-100' onClick={(e)=> handleApplyFilter(e)} disabled={loading.isApplyFilter || loading.isClearFilter}>Search</button>}
            </Col>
            <Col md={2} xs={6} className=' d-flex align-items-end mb-2'>
                {loading.isClearFilter ? <ButtonLoader classes={'btn text-secondary border-secondary w-100'} /> :<button className='text-secondary btn border-secondary w-100' onClick={(e) => resetFilter(e)} disabled={loading.isApplyFilter || loading.isClearFilter}>Clear</button>}
            </Col>
        </Row>
    )
}

export default SearchFilter;