import React from 'react'
import { ProgressBar, Table } from 'react-bootstrap'
import "../Table/ManuscriptTable/TableDetails.scss"

const TableLoader = ({headLength, dataLength}) => {
  return (
    <Table responsive className='tableBlock mt-2'>
            <thead>
                <tr>
                    {[...Array(headLength+1).keys()].map((val, i)=>(
                            <th className='py-4' key={val}><ProgressBar animated now={100} variant="secondary" /></th>
                    ))}
                </tr>
            </thead>
            <tbody>
                    {[...Array(dataLength).keys()].map((val)=>(
                        <tr key={val}>
                            <td width="7%"><ProgressBar animated now={100} variant="secondary" /></td>
                            {[...Array(headLength).keys()].map((val)=>(
                            <td key={val}><ProgressBar animated now={100} variant="secondary" /></td>
                            ))}
                        </tr>
                    ))}
                
            </tbody>

    </Table>
  )
}

export default TableLoader
