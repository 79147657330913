import React from "react";
import { useDispatch } from "react-redux";
import {
  setGraphType,
  setManuscriptModal,
} from "../../../Redux/Action/filter.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import TooltipButton from "../../TooltipButton/TooltipButton";
import ReactGA from "react-ga4"
import { title, toTitleCase } from "../../Filter/Filter";

const TableItem = ({
  rowData,
  headerDetails,
  dataKey,
  headers,
  gridTitle,
  setExpandableGridData,
  getExpandableTableDetails,
  setExpand,
  expand,
  setCurrent,
  parentData,
  setParrentData,
  graphType
}) => {
  const dispatch = useDispatch();

  const handleGridClick = (columnName) => {
    const field = headers[0]?.field;
    let obj = {};
    switch (gridTitle) {
      case "Access Type":
        obj["graphType"] = "accessTypeTrend";
        break;
      case "Publishers":
        obj["graphType"] = "publisherTrend";
        break;
      case "Journals by Publication Count":
        console.log("===graph", graphType)
        if(graphType==="categoriesGraph"){
          obj["graphType"] = "subjectCascade";
        }else {
          obj["graphType"] = "journalCascade";
        }
        break;
      case "Journal":
        obj["graphType"] = "publicationTrend";
        break;
      
      default:
        obj["graphType"] = "journalTrend";
    }
    obj[field] = rowData[field];
    obj["filterStatus"] = columnName;
    let isTitle = false;
    dispatch(
      setGraphType({
        title: !isTitle
          ? rowData[field] + " > " + headerDetails.display
          : `${parentData?.data[parentData?.header[0]?.field]} > ${
              rowData[field]
            }`,
        graphType: obj,
      })
    );
    // console.log("click===", parentData?.data[parentData?.header[0]?.field], headerDetails.display)
    ReactGA.event({
      category: gridTitle,
      action: `Manuscript Details Modal Clicked`,
      label: `title= ${!isTitle? headerDetails.display: parentData?.data[parentData?.header[0]?.field]}, column= ${headerDetails.display}`
    });

    dispatch(setManuscriptModal(true));
  };

  const handleExpand = (d) => {
    ReactGA.event({
      category: toTitleCase(title),
      action: `Expand Grid Clicked`,
      label: d.p_publisher? `p_publisher= ${d?.p_publisher}, total_count= ${d.journalCount}` : `s_journal= ${d?.s_journal}, total_count= ${d.journalCount}`
    });
    setExpand(true);
    getExpandableTableDetails();
    setParrentData({
      header: headers,
      data: d,
    });
  };

  const handleCollapse = () => {
    setExpandableGridData({});
    setCurrent(0);
    setExpand(false);
  };

  return (
    <>
      <td className={``} key={dataKey}>
        {headerDetails?.dataType === "url" &&
        rowData[headerDetails?.field] !== 0 ? (
          <>
            <span
              className="linkText cursor-pointer"
              onClick={() => handleGridClick(headerDetails?.field)}
            >
              {rowData[headerDetails?.field] || "NA"}
            </span>
          </>
        ) : headerDetails?.expandable ? (
          <>
            {rowData[headerDetails?.field] ===0 ?
            <span>
              {rowData[headerDetails?.field]}
            </span>
            
            :!expand ? (
              <TooltipButton
                type="string"
                tooltipMessage="Expand"
              >
                <span
                  className="expandableBlock linkText cursor-pointer"
                  onClick={() => handleExpand(rowData)}
                >
                  {rowData[headerDetails?.field]}{" "}
                  <span className="linkText fs-12">
                    {" "}
                    <FontAwesomeIcon
                      className=""
                      size="xs"
                      icon={faAngleDoubleDown}
                    />
                  </span>
                </span>
              </TooltipButton>
            ) : (
              <TooltipButton
                type="string"
                tooltipMessage="Collapse"
              >
                <span
                  className="expandableBlock text-success cursor-pointer"
                  onClick={() => handleCollapse(rowData)}
                >
                  {rowData[headerDetails?.field]}{" "}
                  <span className="linkText fs-12">
                    {" "}
                    <FontAwesomeIcon
                      className="text-success"
                      size="xs"
                      icon={faAngleDoubleUp}
                    />
                  </span>
                </span>
              </TooltipButton>
            )}
          </>
        ) : (
          <div>{rowData[headerDetails?.field]}</div>
        )}
      </td>
    </>
  );
};

export default TableItem;
