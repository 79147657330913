import { ActionType } from "./Types"

export function openLoginModal(){
    return {
        type: ActionType.LOGIN_MODAL_OPEN,
        payload: true
    }
}

export function closeLoginModal(){
    return {
        type: ActionType.LOGIN_MODAL_CLOSE,
        payload: false
    }
}