export const ActionType = {
    HANDLE_FORM_CHANGE : "HANDLE_FORM_CHANGE",
    SEARCH_DATE_RANGE :  "SEARCH_DATE_RANGE",
    APPLY_FILTER : "APPLY_FILTER",
    CLEAR_FILER : "CLEAR_FILER",
    SET_DATE_RAGE: "SET_DATE_RAGE",
    SET_REJECTING_JOURNALS: "SET_REJECTING_JOURNALS",
    SET_ACCEPTING_JOURNALS: "SET_ACCEPTING_JOURNALS",
    SET_PUBLISHERS: "SET_PUBLISHERS",
    SET_MANUSCRIPT_GRID_DATA: "SET_MANUSCRIPT_GRID_DATA",
    SET_JOURNAL_GRID_DATA: "SET_JOURNAL_GRID_DATA",
    SET_TABLE_LOADER:"SET_TABLE_LOADER", 
    SET_CURRENT: "SET_CURRENT",
    SET_SORT: "SET_SORT",
    SET_STATUS: "SET_STATUS",
    SET_ACCESS_TYPE: "SET_ACCESS_TYPE",
    SET_JOURNAL_TYPE: "SET_JOURNAL_TYPE",
    SET_MANUSCRIPT_MODAL: "SET_MANUSCRIPT_MODAL",
    SET_GRAPH_TYPE: "SET_GRAPH_TYPE",
    LOGIN_MODAL_OPEN: "MODAL_OPEN",
    LOGIN_MODAL_CLOSE: "MODAL_CLOSE",
    TOKEN_EXPIRED: "TOKEN_EXPIRED",
    TOKEN_REFRESHED: "TOKEN_REFRESHED",
    SET_REJECTING_JOURNALS_LIST: "SET_REJECTING_JOURNALS_LIST",
    SET_ACCEPTING_JOURNALS_LIST: "SET_ACCEPTING_JOURNALS_LIST",
    SET_PUBLISHERS_LIST: "SET_PUBLISHERS_LIST",
    IS_PAGE_RELOAD: "IS_PAGE_RELOAD"
}
