import React from "react";
import { Row, Col } from "react-bootstrap";
import TooltipButton from "../../../TooltipButton/TooltipButton";
import {
  journalCascadeMessage,
  rejectionAndPublicationTrend,
  timeToPublicationMessage,
} from "../../../TooltipButton/TooltipMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import { toTitleCase, title } from "../../../Filter/Filter";
import { SpinnerLoader } from "../../../Loader/Loader";
import NoDataFound from "../../../NotFound/NoDataFound";
// import MultiLine from "./MultiLine";
import StackBarChart from "./StackBarChart";

function ToggleStackBarChart({
  data,
  // title,
  activeBtn,
  setActiveBtn,
  getRejectionChart,
  getPublishedChart,
  onResetChart,
  loader,
}) {
  const title = "Journal " + activeBtn + " Trend"
  return (
    <div className="mt-3 card card-body">
      <Row>
        <Col lg={7} md={12}>
          <h5>
            {title}
            <TooltipButton
              tooltipMessage={rejectionAndPublicationTrend}
              placement="right"
              type={"string"}
              classes={"TooltipDiv"}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="sm"
                className="fs-14 mx-1 text-secondary me-3"
              />
            </TooltipButton>
          </h5>
        </Col>
        <Col lg={5} md={12}>
          <div className="d-flex justify-content-end align-items-center">
            <span className="me-3">By</span>
            <div
              className="btn-group "
              role="group"
              aria-label="Basic mixed styles example"
            >
              <button
                type="button"
                onClick={() => {
                  ReactGA.event({
                    category: toTitleCase(title),
                    action: `Rejection Trend Clicked`,
                  });
                  setActiveBtn("Rejection");
                  getRejectionChart();
                }}
                className={`btn ${
                  activeBtn === "Rejection"
                    ? "btnDefault btn-info"
                    : "border text-muted"
                }`}
                disabled={loader || !data}
              >
                Rejection Trend
              </button>

              <button
                type="button"
                onClick={() => {
                  ReactGA.event({
                    category: toTitleCase(title),
                    action: `Published Trend Clicked`,
                  });
                  setActiveBtn("Published");
                  getPublishedChart();
                }}
                className={`btn ${
                  activeBtn === "Published"
                    ? "btnDefault btn-info"
                    : "border text-muted "
                }`}
                disabled={loader || !data}
              >
                Published Trend
              </button>
            </div>
          </div>
        </Col>
      </Row>
      {loader ? (
        <div className="loaderSection">
          <SpinnerLoader size="lg" />
        </div>
      ) : data ? (
        <StackBarChart data={data} activeBtn={activeBtn} />
      ) : (
        <NoDataFound />
      )}
    </div>
  );
}

export default ToggleStackBarChart;
