import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import AxiosService from "../../utils/AxiosService";
import "./DateRange.scss";
import { setCurrentPage, setDateRange } from "../../Redux/Action/filter.action";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { SpinnerLoader } from "../Loader/Loader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ReactGA from "react-ga4"
import { title, toTitleCase } from "../Filter/Filter";


const DateRange = ({ loading, setLoading }) => {
  const { dateRange } = useSelector((state) => state.allFilters);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  const dispatch = useDispatch();
  const axiosService = new AxiosService();
  const [dataLoading, setDataLoading] = useState(false);

  const handleGoAction = async () => {
   

    const m1 = moment(startDate, "YYYY-MM-DD");
    const m2 = moment(endDate, "YYYY-MM-DD");
    ReactGA.event({
      category: toTitleCase(title),
      action: 'Date Range changed',
      label: `${JSON.stringify({
        rejectionStartDate: m1.format("YYYY-MM-DD"),
        rejectionEndDate: m2.format("YYYY-MM-DD"),
      })}`
    });

    dispatch(
      setDateRange({
        rejectionStartDate: m1.format("YYYY-MM-DD"),
        rejectionEndDate: m2.format("YYYY-MM-DD"),
        defualtStartDate: dateRange.defualtStartDate,
        defualtEndDate: dateRange.defualtEndDate,
        lastUpdatedDate: dateRange.lastUpdatedDate,
      })
    );
    dispatch(setCurrentPage(1));
    setLoading({ ...loading, isGo: true });
  };

  useEffect(() => {
    if(dateRange?.rejectionStartDate && dateRange?.rejectionEndDate){
      setStartDate(new Date(dateRange?.rejectionStartDate));
      setEndDate(new Date(dateRange?.rejectionEndDate));
      setMinDate(new Date(dateRange?.defualtStartDate));
      setMaxDate(new Date(dateRange?.defualtEndDate));
    }
  }, [dateRange]);

  const handleResetAction = async () => {
    
    setDataLoading(true);
    await axiosService
      .getMetaInfo("")
      .then((response) => {
        setDataLoading(false);
        const m1 = moment(response?.data?.rejectionStartDate, "YYYY-MM-DD");
        const m2 = moment(response?.data?.rejectionEndDate, "YYYY-MM-DD");
        dispatch(
          setDateRange({
            rejectionStartDate: m1.format("YYYY-MM-DD"),
            rejectionEndDate: m2.format("YYYY-MM-DD"),
            defualtStartDate: m1.format("YYYY-MM-DD"),
            defualtEndDate: m2.format("YYYY-MM-DD"),
            lastUpdatedDate: response?.data?.lastUpdatedDate,
          })
        );

        setStartDate(new Date(m1.format("YYYY-MM-DD")));
        setEndDate(new Date(m2.format("YYYY-MM-DD")));

        setMinDate(new Date(m1.format("YYYY-MM-DD")));
        setMaxDate(new Date(m2.format("YYYY-MM-DD")));

        dispatch(setCurrentPage(1));
        setLoading({ ...loading, isGo: true });
        ReactGA.event({
          category: toTitleCase(title),
          action: 'Reset Date Range',
          label: `${JSON.stringify({
            rejectionStartDate: m1.format("YYYY-MM-DD"),
            rejectionEndDate: m2.format("YYYY-MM-DD"),
          })}`
        });
      })
      .catch((error) => {
        setDataLoading(false);
        toast.error(error?.message);
      });
  };

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div className="border-end pe-1 datePickerBlock">
      <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        ref={ref}
      />
      <FontAwesomeIcon icon={faAngleDown} className="" onClick={onClick} />
    </div>
  ));

  let years = [];
  for (let i = 2010; i < 2025; i++) {
    years.push(i);
  }

  //const years =
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="d-flex align-items-center justify-content-end sm-flex-left">
      <span className="me-3 mb-3">Rejection Date Range</span>
      <div>
        <div className="btn-group mc-btn-group border rounded bg-white">
          <DatePicker
            selected={startDate}
            selectsStart
            value={startDate}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={endDate}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                className="mb-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  className="btn btn-sm border btn-light mx-1"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {"<"}
                </button>
                <select
                  className="form-select form-select-sm w-auto"
                  value={date.getFullYear()}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[date.getMonth()]}
                  className="form-select form-select-sm w-auto"
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  className="btn btn-sm border btn-light mx-1"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {">"}
                </button>
              </div>
            )}
            customInput={<ExampleCustomInput />}
            onChange={(date) => {
              setStartDate(date);
            }}
            dateFormat="yyyy-MM-dd"
            disabled={dataLoading}
          />
          <DatePicker
            selectsEnd
            selected={endDate}
            value={endDate}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={maxDate}
            customInput={<ExampleCustomInput />}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                className="mb-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  className="btn btn-sm border btn-light mx-1"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {"<"}
                </button>
                <select
                  className="form-select form-select-sm w-auto"
                  value={date.getFullYear()}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[date.getMonth()]}
                  className="form-select form-select-sm w-auto"
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  className="btn btn-sm border btn-light mx-1"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {">"}
                </button>
              </div>
            )}
            onChange={(date) => {
              setEndDate(date);
            }}
            dateFormat="yyyy-MM-dd"
            disabled={dataLoading}
          />

          <button
            className="btn btnDefault btn-info"
            onClick={handleGoAction}
            disabled={!startDate || !endDate}
          >
            {loading.isGo ? (
              <span>
                <SpinnerLoader />
              </span>
            ) : (
              "Go"
            )}
          </button>
          <OverlayTrigger overlay={<Tooltip id="resetButton">Reset to default date range.</Tooltip>}>
            <button
              className="btn btnDefault text-secondary border-secondary"
              onClick={handleResetAction}
              disabled={!startDate || !endDate}
            >
              <FontAwesomeIcon icon={faRefresh} />
            </button>
          </OverlayTrigger>
        </div>
        <p className="mb-0 text-end fs-12">
          Last updated{" "}
          {dateRange.lastUpdatedDate
            ? moment(dateRange.lastUpdatedDate).format("DD MMMM YYYY")
            : "NA"}
        </p>
      </div>
    </div>
  );
};

export default DateRange;
