import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Filter, { handleGAEvent } from "../../components/Filter/Filter";
import AxiosService from "../../utils/AxiosService";
import { setDateRange, setTableLoader } from "../../Redux/Action/filter.action";

import TableGridLayout from "../../components/TableGridLayout/TableGridLayout";
import ToggleMultiline from "../../components/graphs/D3/MultiLine/ToggleMultiline";
import ToggleStackBarChart from "../../components/graphs/D3/StackBarChart/ToggleStackBarChart";
import DoubleLayerStackBarChart from "../../components/graphs/D3/DoubleLayerStackBarChart/DoubleLayerStackBarChart";
import { SpinnerLoader } from "../../components/Loader/Loader";
import MultiBarCustomChart from "../../components/graphs/D3/MultiBarCustomChart/MultiBarCustomChart";

const InsightsByJournalCascade = () => {
  const {
    searchValue,
    dateRange,
    status,
    currentPage,
    rejectingJournal,
    acceptingJournal,
    publishers,
    accessType,
    isPageReload,
  } = useSelector((state) => state.allFilters);

  const dispatch = useDispatch();
  const axiosService = new AxiosService();
  const [loading, setLoading] = useState({
    isApplyFilter: false,
    isClearFilter: false,
    isGo: false,
  });
  const [chartLoader, setChartLoader] = useState(false);
  const [graphLoader, setGraphLoader] = useState(false);
  const [reqBody, setReqBody] = useState({});

  const [chartData, setChartData] = useState([]);
  const [fullChartData, setFullChartData] = useState({});

  const [activeBtn, setActiveBtn] = useState("Rejection");

  const [gridData, setGridData] = useState({});
  const [sort, setSort] = useState({
    field: "totalPublication",
    order: "desc",
  });
  const [start, setStart] = useState(0);
  const offset = 10;

  const getAllRecords = async () => {
    let startDate = "";
    let endDate = "";
    if (
      dateRange.rejectionStartDate.length > 10 ||
      dateRange.rejectionEndDate.length > 10
    ) {
      startDate = dateRange.rejectionStartDate.slice(0, 10);
      endDate = dateRange.rejectionEndDate.slice(0, 10);
    } else {
      startDate = dateRange.rejectionStartDate;
      endDate = dateRange.rejectionEndDate;
    }
    dispatch(setTableLoader(true));
    setGraphLoader(true);
    const start = (currentPage - 1) * offset;
    setStart(start);
    const reqBody = {
      vendor: "",
      start_date: startDate,
      end_date: endDate,
      status: status,
      searchTerm: searchValue,
      filter: {
        s_journal: rejectingJournal,
        p_journal: acceptingJournal,
        p_publisher: publishers,
      },
      // sort: {
      //   field: sort.field,
      //   order: sort.order,
      // },
      // card_type: activeBtn == "Rejection" ? "rejected" : "published",
    };
    if (accessType) {
      reqBody["filter"].oa = true;
    }

    setChartLoader(true);
    axiosService
      .getRejectingJournalCascadeChart(start, offset, reqBody)
      .then((response) => {
        // console.log("response.data => ", response.data.publishedTrend);
        if (response?.data) {
          setGraphLoader(false);
          setLoading({
            ...loading,
            isApplyFilter: false,
            isClearFilter: false,
            isGo: false,
          });
          setFullChartData(response?.data.publishedTrend);
          setChartLoader(false);

          // if (activeBtn === "Rejection") {
          //   setChartData(response.data && response.data.rejectionTrend);
          // } else if (activeBtn === "Published") {
          setChartData(response.data && response.data.publishedTrend);
          // }
        }
      })
      .catch((error) => {
        setGraphLoader(false);
        setChartLoader(false);
        setChartData({});
      });

    axiosService
      .getRejectingJournalCascadeGrid(start, offset, reqBody)
      .then((response) => {
        if (response?.data) {
          dispatch(setTableLoader(false));
          setLoading({
            ...loading,
            isApplyFilter: false,
            isClearFilter: false,
            isGo: false,
          });
          setGridData(response?.data);
        }
      })
      .catch((error) => {
        dispatch(setTableLoader(false));
        setGridData({});
      });
    setReqBody(reqBody);
    handleGAEvent(loading, reqBody);
  };

  useEffect(() => {
    if (dateRange.rejectionStartDate) {
      getAllRecords();
    }
  }, [currentPage, sort, isPageReload, activeBtn]);

  useEffect(() => {
    if (
      (loading.isApplyFilter || loading.isGo) &&
      dateRange.rejectionStartDate
    ) {
      setSort({
        field: "totalPublication",
        order: "desc",
      });
    }
  }, [loading.isApplyFilter, loading.isGo]);

  useEffect(() => {
    if (activeBtn === "Published") {
      setActiveBtn("Rejection");
    }

    setSort({
      field: "totalPublication",
      order: "desc",
    });
  }, [loading.isClearFilter]);

  // const getRejectionChart = () => {
  //   setGraphLoader(true);

  //   setChartData(fullChartData.rejectionTrend);

  //   setTimeout(() => {
  //     setGraphLoader(false);
  //   }, 500);
  // };

  // const getPublishedChart = () => {
  //   setGraphLoader(true);

  //   setChartData(fullChartData.publishedTrend);

  //   setTimeout(() => {
  //     setGraphLoader(false);
  //   }, 500);
  // };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Filter
        loading={loading}
        setLoading={setLoading}
        getAllRecords={getAllRecords}
      />

      {/* <ToggleStackBarChart
        data={chartData}
        activeBtn={activeBtn}
        setActiveBtn={setActiveBtn}
        getRejectionChart={getRejectionChart}
        getPublishedChart={getPublishedChart}
        loader={graphLoader}
        // title="Journal Cascade"
        loading={loading}
      /> */}

      {chartLoader ? (
        <div className="loaderSection mt-4 card card-body">
          <SpinnerLoader size="lg" />
        </div>
      ) : (
        <div>
          {/* {Object.keys(chartData).length && (
          <DoubleLayerStackBarChart
            data={chartData}
            journalType={"journalCascade"}
          />
        )} */}
          {/* <MultiLine data={chartData} journalType={"journalCascade"} /> */}
          <MultiBarCustomChart
            data={chartData}
            journalType={"journalCascade"}
          />
        </div>
      )}

      {Object.keys(gridData).length ? (
        <TableGridLayout
          data={gridData}
          start={start}
          setStart={setStart}
          offset={offset}
          gridTitle={"Journals by Publication Count"}
          getAllRecords={getAllRecords}
          sort={sort}
          setSort={setSort}
          reqBody={reqBody}
          gridType="rejectingJournalCascadeGrid"
        />
      ) : null}
    </div>
  );
};

export default InsightsByJournalCascade;
