import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import { Col, Row } from "react-bootstrap";
import TooltipButton from "../../../TooltipButton/TooltipButton";
import { accessTypeMessage } from "../../../TooltipButton/TooltipMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import { title, toTitleCase } from "../../../Filter/Filter";
import { useDispatch } from "react-redux";
import {
  setGraphType,
  setManuscriptModal,
} from "../../../../Redux/Action/filter.action";
import Graphdownload from "../Graphdonwload/Graphdownload";
import { SpinnerLoader } from "../../../Loader/Loader";

import "./DonutGraph.scss";
import NoDataFound from "../../../NotFound/NoDataFound";

const loadGraph = (data, dispatch, active) => {
  const width = 680;
  const height = 460;
  const radius = 240;

  const outerRadius =
    Math.min(width - 2 * 600, height - 2 * 200) / 2 > 100
      ? Math.min(width - 2 * 600, height - 2 * 200) / 2
      : 180;

  const innerRadius = (outerRadius * 80) / 100;

  const arcOpacity = "1";
  const arcOpacityHover = "1";
  const otherArcOpacityHover = "0.2";
  const arcStroke = "2px";
  const arcStrokeHover = "3px";

  const sum = data[0]?.y + data[1]?.y;
  const colourArray = ["#4CAF50", "#E91E63"];

  if (!d3.select("#donut_graph > svg").empty()) {
    d3.select("#donut_graph > svg").remove();
  }

  let svg = d3
    .select("#donut_graph")
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

  const color = d3
    .scaleOrdinal()
    .domain([data[0].name, data[1].name])
    .range(colourArray);

  // Compute the position of each group on the pie:
  let pie = d3
    .pie()
    .sort(null) // Do not sort group by size
    .value(function (d) {
      return d.y;
    });

  let total_percentage = 0;

  data.forEach((d) => {
    total_percentage += d.y;
  });
  let modified_data = data.map((d) => {
    const value = (d.y / total_percentage) * 100;
    return { name: d.name, y: Math.round(value * 10) / 10 };
  });

  let data_ready = pie(modified_data);

  // The arc generator
  let arc = d3
    .arc()
    .innerRadius(innerRadius) // This is the size of the donut hole
    .outerRadius(outerRadius)
    .cornerRadius(3);

  // Another arc that won't be drawn. Just for labels positioning
  let outerArc = d3
    .arc()
    .innerRadius(radius * 0.9)
    .outerRadius(radius * 0.9);

  const arcTweenEnter = (d) => {
    var i = d3.interpolate(d.startAngle, d.endAngle);
    var j = d3.interpolate(0, d.startAngle);
    return function (t) {
      d.startAngle = j(t);
      d.endAngle = i(t);

      return arc(d);
    };
  };

  // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
  svg
    .selectAll("allSlices")
    .data(data_ready)
    .enter()
    .append("path")
    .attr("class", (d, i) => `arc_${i}`)
    .classed("arc", true)
    .attr("d", arc)
    .attr("fill", function (d) {
      return color(d.data.name);
    })
    .attr("stroke", "white")
    .on("click", function (event, d) {
      
     if(d?.value){
      ReactGA.event({
        category: toTitleCase(title),
        action: `Pie Graph Clicked`,
        label: ` accessType=
            ${d.data.name === "OTHERS" ? "OTHERS" : "OPEN ACCESS"},
          filterStatus= active`,
      });
      dispatch(
        setGraphType({
          title: `${d.data?.name === "OTHERS" ? "OTHERS" : "OPEN ACCESS"} > ${
            active.toLowerCase() === "internal"
              ? "WITH US"
              : active.toLowerCase() === "totalpublication"
              ? "OVERALL"
              : "ELSEWHERE"
          }`,
          graphType: {
            graphType: "accessTypeTrend",
            accessType: d.data?.name === "OTHERS" ? "OTHERS" : "OPEN ACCESS",
            filterStatus: active,
          },
        })
      );
      dispatch(setManuscriptModal(true));
     }
    })
    .style("stroke-width", arcStroke)
    .style("opacity", arcOpacity)
    .on("mouseover", function (event, d) {
      const arcOver = d3
        .arc()
        .innerRadius(outerRadius)
        .outerRadius(outerRadius + 10)
        .startAngle(d.startAngle)
        .endAngle(d.endAngle)
        .cornerRadius(3);

      d3.select(`g:has(path.arc_${d.index})`)
        .append("path")
        .classed("newArc", true)
        .attr("d", arcOver)
        .style("opacity", "0.2")
        .attr("fill", colourArray[d.index]);

      d3.selectAll(".arc").style("opacity", otherArcOpacityHover);

      d3.selectAll('[class*="group_"]').style("opacity", otherArcOpacityHover);
      d3.selectAll(`.group_${d.index}`).style("opacity", arcOpacity);

      d3.select(this)
        .style("opacity", arcOpacityHover)
        .style("stroke-width", arcStrokeHover)
        .style("cursor", "pointer");

      //Tooltip
      d3.select("#donut_graph")
        .append("div")
        .attr("id", "tooltip")
        .style("position", "absolute")
        .style("background-color", "white")
        .style("opacity", "1")
        .style("font-size", "12px")
        .style("box-shadow", "1px 1px 5px 1px black")
        .style("text-align", "center")
        .style("padding", "4px")
        .style("margin", "2px")
        .html(
          `<small>${data[d.index].name}</small><br/><b>${(
            (data[d.index].y * 100) /
            sum
          ).toFixed(1)}%</b>`
        );
    })
    .on("mouseout", function (d) {
      d3.selectAll(".arc").style("opacity", arcOpacity);
      d3.select(this).style("stroke-width", arcStroke).style("cursor", "none");
      d3.selectAll('[class*="group_"]').style("opacity", arcOpacity);
      d3.select(`g path.newArc`).remove();

      //Tooltip removed
      d3.select("#tooltip").remove();
    })
    .on("mousemove", function (event, d) {
      // Tooltip position
      d3.select("#tooltip")
        .style("left", event.screenX - 50 + "px")
        .style("top", event.offsetY + 20 + "px");
    })
    .transition("Uploading")
    .duration(750)
    .attrTween("d", arcTweenEnter);

  // Add the polylines between chart and labels:
  svg
    .selectAll("allPolylines")
    .data(data_ready)
    .enter()
    .append("polyline")
    .attr("stroke", (d) => colourArray[d.index])
    .attr("class", (d, i) => `group_${i}`)
    .style("fill", "none")
    .attr("stroke-width", 1)
    .attr("points", function (d) {
      var posA = arc.centroid(d); // line insertion in the slice
      var posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
      var posC = outerArc.centroid(d); // Label position = almost the same as posB
      posC[0] = posC[0] > 0 ? posC[0] + 10 : posC[0] - 10;
      return [posA, posB, posC];
    })
    .style("opacity", 0)
    .transition("polyLineTransition")
    .duration(1000)
    .style("opacity", 1);

  // Add the polylines between chart and labels:
  svg
    .selectAll("allLabels")
    .data(data_ready)
    .enter()
    .append("text")
    .attr("class", (d, i) => `group_${i}`)
    .text(function (d) {
      return d.data.name + "(" + d.data.y + "%)";
    })
    .attr("transform", function (d) {
      let xpos = 13;
      if (d.data.name == "OTHERS" && d.data.y == 0) {
        xpos = 90;
      }

      var pos = outerArc.centroid(d);
      pos[0] = pos[0] > 0 ? pos[0] + xpos : pos[0] - 13;
      return "translate(" + pos + ")";
    })
    .style("text-anchor", function (d) {
      var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
      return midangle < Math.PI ? "start" : "end";
    })
    .attr("dominant-baseline", "middle")
    .style("font-size", "0.7em")
    .style("font-weight", "bold")
    .style("opacity", 0)
    .transition("labelTransition")
    .duration(1000)
    .style("opacity", 1);

  return d3.select("#donut_graph > svg").node();
};

function DonutGraph(props) {
  const [activeBtn, setActiveBtn] = useState("totalPublication");
  const [chartData, setChartData] = useState({});
  const [validContnet, setValidContent] = useState(true);

  const [svg, setSvg] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(props.data).length) {
      setActiveBtn("totalPublication");
      setChartData(props.data.total);
    }
  }, [props.data]);

  useEffect(() => {
    if (Object.keys(chartData).length) {
      let dataCheck = false;

      chartData.forEach((element) => {
        if (element.y !== 0) {
          dataCheck = true;
        }
      });

      if (dataCheck) {
        setValidContent(true);

        setSvg(loadGraph(chartData, dispatch, activeBtn));
      } else {
        if (!d3.select("#donut_graph > svg").empty()) {
          d3.select("#donut_graph > svg").remove();
        }

        setValidContent(false);
        setSvg("");
      }
    }
  }, [chartData]);

  return (
    <div className="card card-body accessTypeStyle mt-3">
      <Row>
        <Col md={6}>
          <h5>
            Access Type
            <TooltipButton
              tooltipMessage={accessTypeMessage}
              placement="right"
              type={"string"}
              classes={"TooltipDiv"}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="sm"
                className="fs-14 mx-1 text-secondary"
              />
            </TooltipButton>
          </h5>
        </Col>
        <Col md={6}>
          <div className="d-flex  align-items-center button-group-donut_graph"> { //justify-content-end 
            }
            <div
              className="btn-group w-75"
              role="group"
              aria-label="Basic mixed styles example"
            >
              <button
                type="button"
                onClick={() => {
                  ReactGA.event({
                    category: toTitleCase(title),
                    action: "Overall button clicked",
                  });
                  const updatedVal = props.data?.total;
                  setChartData(updatedVal);
                  setActiveBtn("totalPublication");
                }}
                className={`btn ${
                  activeBtn === "totalPublication"
                    ? "btnDefault btn-info"
                    : "border text-muted"
                }`}
              >
                Overall
              </button>
              <button
                type="button"
                onClick={() => {
                  ReactGA.event({
                    category: toTitleCase(title),
                    action: " With us button clicked",
                  });
                  const updatedVal = props.data?.Internal;
                  setChartData(updatedVal);
                  setActiveBtn("Internal");
                }}
                className={`btn ${
                  activeBtn === "Internal"
                    ? "btnDefault btn-info"
                    : "border text-muted"
                }`}
              >
                With us
              </button>
              <button
                type="button"
                onClick={() => {
                  ReactGA.event({
                    category: toTitleCase(title),
                    action: " Elsewhere button clicked",
                  });
                  const updatedVal = structuredClone(props.data?.External);
                  setChartData(updatedVal);
                  setActiveBtn("External");
                }}
                className={`btn ${
                  activeBtn === "External"
                    ? "btnDefault btn-info"
                    : "border text-muted "
                }`}
              >
                Elsewhere
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <div className="mt-3 position-relative">
        {props.loader ? (
          <div className="loaderSection">
            <SpinnerLoader size="lg" />
          </div>
        ) : (
          <>
            {!validContnet && <NoDataFound />}
            {svg && <Graphdownload svg={svg} filename={toTitleCase(title)} />}
            <div className="chart-container_donut_graph">
              <div
                id="donut_graph"
                className="d-flex justify-content-center align-items-center"
              ></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DonutGraph;
