import React from "react";
import { useDispatch } from "react-redux";
import {
  setGraphType,
  setManuscriptModal,
} from "../../../Redux/Action/filter.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import TooltipButton from "../../TooltipButton/TooltipButton";
import ReactGA from "react-ga4";
import { title, toTitleCase } from "../../Filter/Filter";

const TableItem = ({
  rowData,
  headerDetails,
  dataKey,
  headers,
  gridTitle,
  setExpandableGridData,
  getExpandableTableDetails,
  setExpand,
  expand,
  setCurrent,
  parentData,
  parentRowData,
  setParrentData,
  graphType,
}) => {
  const dispatch = useDispatch();

  const handleGridClick = (headerDetails) => {
    const parentColumnName = headerDetails.parentColName;
    const columnName =
      headerDetails.field == "with_us" ? "Internal" : "External";
    const field = headerDetails?.parentRowField;

    let obj = {};
    switch (gridTitle) {
      case "Access Type":
        obj["graphType"] = "accessTypeTrend";
        break;
      case "Publishers":
        obj["graphType"] = "publisherTrend";
        break;
      case "Journals by Publication Count":
        if (graphType === "categoriesGraph") {
          obj["graphType"] = "subjectCascade";
        } else {
          obj["graphType"] = "journalCascade";
        }
        break;
      case "Journal":
        obj["graphType"] = "publicationTrend";
        break;

      default:
        obj["graphType"] = "journalTrend";
    }
    obj[headerDetails?.parentRowField] =
      parentRowData[headerDetails?.parentRowField]; //headerDetails?.parentRowName ||
    obj["filterStatus"] = columnName;
    obj["s_rejection_date"] = headerDetails?.parentColField;
    let isTitle = false;
    dispatch(
      setGraphType({
        title: !isTitle
          ? parentRowData[headerDetails?.parentRowField] +
            " > " +
            headerDetails?.parentColName
          : `${parentData?.data[parentData?.header[0]?.field]} > ${
              rowData[field]
            }`,
        graphType: obj,
      })
    );
    ReactGA.event({
      category: gridTitle,
      action: `Manuscript Details Modal Clicked`,
      label: `title= ${
        !isTitle
          ? headerDetails?.parentColName
          : parentData?.data[parentData?.header[0]?.field]
      }, column= ${headerDetails?.parentColName}`,
    });

    dispatch(setManuscriptModal(true));
  };

  const handleExpand = (d) => {
    ReactGA.event({
      category: toTitleCase(title),
      action: `Expand Grid Clicked`,
      label: d.p_publisher
        ? `p_publisher= ${d?.p_publisher}, total_count= ${
            undefined || d.journalCount
          }`
        : `s_journal= ${d?.s_journal}, total_count= ${d.journalCount}`,
    });
    setExpand(true);
    getExpandableTableDetails();
    setParrentData({
      header: headers,
      data: d,
    });
  };

  const handleCollapse = () => {
    setExpandableGridData({});
    setCurrent(0);
    setExpand(false);
  };

  return (
    <td
      className={`${headerDetails.field == "s_journal" ? "" : "text-center"}`}
      key={dataKey}
    >
      {headerDetails?.dataType === "url" &&
      rowData[headerDetails?.field] !== 0 ? (
        <>
          <span
            className="linkText cursor-pointer"
            onClick={() => handleGridClick(headerDetails)}
          >
            {rowData[headerDetails?.field] || "NA"}
          </span>
        </>
      ) : headerDetails?.expandable ? (
        <>
          {rowData[headerDetails?.field] === 0 ? (
            <span>{rowData[headerDetails?.field]}</span>
          ) : !expand ? (
            <TooltipButton type="string" tooltipMessage="Expand">
              <span
                className="expandableBlock linkText cursor-pointer"
                onClick={() => handleExpand(rowData)}
              >
                {rowData[headerDetails?.field]}{" "}
                <span className="linkText fs-12">
                  {" "}
                  <FontAwesomeIcon
                    className=""
                    size="xs"
                    icon={faAngleDoubleDown}
                  />
                </span>
              </span>
            </TooltipButton>
          ) : (
            <TooltipButton type="string" tooltipMessage="Collapse">
              <span
                className="expandableBlock text-success cursor-pointer"
                onClick={() => handleCollapse(rowData)}
              >
                {rowData[headerDetails?.field]}{" "}
                <span className="linkText fs-12">
                  {" "}
                  <FontAwesomeIcon
                    className="text-success"
                    size="xs"
                    icon={faAngleDoubleUp}
                  />
                </span>
              </span>
            </TooltipButton>
          )}
        </>
      ) : (
        <div>{rowData[headerDetails?.field]}</div>
      )}
    </td>
  );
};

export default TableItem;
