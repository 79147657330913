import { ActionType as actionType } from "../Action/Types"

const initState ={
    journalGrid: {
        data:[],
        total: 0,
        config:[]
    }
}

export const journalReducer = (state= initState, {type, payload}) => {
    switch(type) {
        case actionType.SET_JOURNAL_GRID_DATA :
           return ({...state, journalGrid: payload})

        default:
            return state

    }
}
