import { useState } from "react";
import "./CustomeAutoComplete.scss";
import SelectComponent from "./SelectComponent";

const MultiSelectCheckbox = ({
  options,
  title,
  name,
  placeholder,
  onFormChange,
  onSelectAllChange,
  selectedValue,
  onClear,
}) => {
  const [selectedOption, setSelectedOption] = useState("");

  return (
    <div>
      <SelectComponent
        options={options}
        onChange={(item) => setSelectedOption(item)}
        selectedKey={selectedOption}
        placeholder={placeholder}
        title={title}
        name={name}
        onFormChange={onFormChange}
        onSelectAllChange={onSelectAllChange}
        selectedValue={selectedValue}
        onClear={onClear}
      />
    </div>
  );
};

export default MultiSelectCheckbox;
