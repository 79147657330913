import { ActionType } from "./Types";

export const setJournalType = (payload) => ({
    type: ActionType.SET_JOURNAL_TYPE,
    payload: payload
})

export const setJournalGridData =(payload) => ({
    type: ActionType.SET_JOURNAL_GRID_DATA,
    payload: payload
})