import { ActionType as actionType } from "../Action/Types"

const initState = {
    searchValue: "",
    accessType: false,
    rejectingJournalsList:[],
    acceptingJournalsList:[],
    publishersList:[],
    rejectingJournal: [],
    acceptingJournal: [],
    publishers: [],
    status: "resolved",
    dateRange: {
        rejectionStartDate: "",
        rejectionEndDate: "",
        lastUpdatedDate: ""
    },
    manuscriptGridData:{
        data: [],
        config: [],
        total: 0
    },
    filterStatus: "",
    currentPage:1,
    tableLoader: false,
    sort: {
        field: "",
        order: ""
    },
    journalType: "rejectingJournal",
    isManuscriptModel: false,
    graphType: {},
    isPageReload: false
}

export const FilterReducer = (state= initState, {type, payload}) => {
    switch(type) {
        case actionType.HANDLE_FORM_CHANGE :
           return ({...state, searchValue: payload})

        case actionType.SET_DATE_RAGE : 
            return ({...state , dateRange: payload})

        case actionType.SET_REJECTING_JOURNALS:
            return ({...state, rejectingJournal: payload})
        
        case actionType.SET_ACCEPTING_JOURNALS: 
            return ({...state, acceptingJournal: payload})

        case actionType.SET_PUBLISHERS: 
            return ({...state, publishers :payload})

        case actionType.SET_MANUSCRIPT_GRID_DATA: 
            return ({...state, manuscriptGridData: payload})

        case actionType.SET_JOURNAL_GRID_DATA: 
            return ({...state, journalGridData: payload})
        
        case actionType.SET_TABLE_LOADER:
            return({...state, tableLoader: payload})

        case actionType.SET_CURRENT:
            return({...state, currentPage: payload})

        case actionType.SET_SORT:
            return ({...state, sort: payload})

        case actionType.SET_STATUS:
            return ({...state, status: payload})

        case actionType.SET_ACCESS_TYPE:
            return ({...state, accessType: payload})

        case actionType.SET_JOURNAL_TYPE:
            return ({...state, journalType: payload})
        
        case actionType.SET_MANUSCRIPT_MODAL:
            return({...state, isManuscriptModel: payload})
           
        case actionType.SET_GRAPH_TYPE: 
            return ({...state, graphType: payload})

        case actionType.SET_ACCEPTING_JOURNALS_LIST:
            return ({...state, acceptingJournalsList: payload})

        case actionType.SET_REJECTING_JOURNALS_LIST:
            return ({...state, rejectingJournalsList: payload})

        case actionType.SET_PUBLISHERS_LIST:
            return ({...state, publishersList: payload})

        case actionType.IS_PAGE_RELOAD:
            return ({...state, isPageReload: payload})

        default: return state
    }
}