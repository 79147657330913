import React, { useState, useEffect } from 'react';
import styles from './Layout.module.scss';
import { Dropdown, Modal, InputGroup, Form, Spinner } from 'react-bootstrap';
import Sidebar from './Sidebar/Sidebar';
import logo from "../../assets/miLogo.png"
import classNames from 'classnames';
import LocalStorageService from '../../utils/auth_service/AuthServiceStorage';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faKey, faEye, faEyeSlash, faClose } from '@fortawesome/free-solid-svg-icons';
import { authLogout } from '../../utils/auth_service/AuthServiceHelper';
import { tokenExpired } from '../../Redux/Action/token.action';
import {ToastContainer, toast} from 'react-toastify';
import { dataDisplayRequest } from '../../utils/auth_service/AuthServiceHelper';
import { openLoginModal } from '../../Redux/Action/login.action';
import { Link } from 'react-router-dom';
import { WORDPRESS_URL } from "../../utils/Constant";

function Header(){
    const [login, setLoggedIn] = useState(false);
    const dispatch = useDispatch();
    const [userName, setUserName] = useState("");
    const localStorageService = LocalStorageService.getService();
    const loginModalToggle = useSelector(state => state.login.loginModalToggle);
    const [changePasswordToggle, setChangePasswordToggle] = useState(false);
    const [passwordFormValues, setPasswordFormValues] = useState({oldPassword: "", newPassword: '', confirmPassword: ''});
    const [passwordFormErrors, setPasswordFormErrors] = useState({oldPassword: "", newPassword: '', confirmPassword: ''});
    const [invalidCredentials, setInvalidCredentials] = useState("");
    const [newPasswordToggle, setNewPasswordToggle] = useState(false);
    const [oldPasswordToggle, setOldPasswordToggle] = useState(false);
    const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);
    const [isPasswordLoading, setIsPasswordLoading] = useState(false);
    const validPassword = RegExp("\\s"); 

    function onLogout(redirect) {
        const params ={"token": localStorageService.getAccessToken()};
        const config = {
            url: "api/v1/oauth/invalidateToken",
            method: "delete",
            headers: params
        };
        authLogout(config).then(response => {
            localStorageService.removeTokens();
            toast.error("Logged out!");
            dispatch(tokenExpired());
            setLoggedIn(false);
            if(redirect){
                window.location.href = WORDPRESS_URL ;}
            else{
                setTimeout(() => {
                    dispatch(openLoginModal());
                }, 1000) 
            }
        }).catch(error => {
            toast.error("Something went wrong!",  {
                autoClose: 3000,
            });
        });
    }

    function handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
        setPasswordFormValues({ ...passwordFormValues, [name]: value });
    }
 
    function handleValidation(event){
        const { name, value } = event.target;
        switch (name) {
            case "oldPassword":
                setPasswordFormErrors({
                    ...passwordFormErrors,
                    [name]: value.length === 0 ?"*Old password is required": value.length < 6 ? "Old password must be 6 characters" : "",
                });
                break;
            case "newPassword":
                setPasswordFormErrors({
                    ...passwordFormErrors,
                    [name]: value.length > 0 ? (value.length > 5 ? (validPassword.test(value) ? "Whitespaces not allowed" : (value === passwordFormValues.oldPassword ? "Old Password & New Password can't be same" : "")) : "Password must be 6 characters") : "*New password is required!",
                });
                break;
            case "confirmPassword":
                setPasswordFormErrors({ ...passwordFormErrors, [name]:  value.length ===0 ?"*Confirm password is required": value !== passwordFormValues.newPassword ? "Passwords didn't match" : ""})
                break;
            default:
                break;
        }
    }

    function closeModal(){
        setPasswordFormErrors({...passwordFormErrors, oldPassword: "", newPassword: "", confirmPassword: ""})
        setChangePasswordToggle(false);
    }

    function handleSubmit(event){
        event.preventDefault();
        if(passwordFormValues.oldPassword.length ===0|| passwordFormValues.oldPassword.length<6 || passwordFormValues.newPassword.length ===0||passwordFormValues.newPassword.length <6 || passwordFormValues.confirmPassword.length ===0 || passwordFormValues.newPassword === passwordFormValues.oldPassword){
            setPasswordFormErrors({
                ...passwordFormErrors,
                oldPassword: passwordFormValues.oldPassword.length ===0 ? "* Old password is required!": passwordFormValues.oldPassword.length<6 ? "Old password must be 6 characters": "",
                newPassword: passwordFormValues.newPassword.length ===0? "* New password is required!":  passwordFormValues.newPassword.length <6 ? "Password must be 6 characters" : passwordFormValues.newPassword === passwordFormValues.oldPassword ?"Old Password & New Password can't be same": "",
                confirmPassword: passwordFormValues.confirmPassword.length ===0? "* Confirm password is required!":passwordFormValues.confirmPassword !== passwordFormValues.newPassword ? "Passwords didn't match": ""
            })
            toast.error("Form submission errors");
            return;
        }
        else if(passwordFormErrors.oldPassword || passwordFormErrors.newPassword || passwordFormErrors.confirmPassword){
            toast.error("Form submission errors");
            return;
        }
        else{
            setIsPasswordLoading(true);
            const config = {
                method: 'post',
                url: '/api/v1/oauth/changePassword',
                data: {"oldPassword": passwordFormValues.oldPassword, "newPassword": passwordFormValues.newPassword, "confirmPassword": passwordFormValues.confirmPassword}
            }
            dataDisplayRequest(config).then(response => {
                setIsPasswordLoading(false);
                if(response.data.result){
                    setInvalidCredentials(false);
                    resetForm();
                    setChangePasswordToggle(false);
                    onLogout(false);
                    toast.success("Password changed successfully")
                }
            }, error => {
                setIsPasswordLoading(false);
                setInvalidCredentials(error.response.data.message); 
            })
        }
    }

    const resetForm = () => {
        setPasswordFormValues({...passwordFormValues, oldPassword: "", newPassword: "", confirmPassword: ""})
        setPasswordFormErrors({...passwordFormErrors, oldPassword: "", newPassword: "", confirmPassword: ""})
    }

    useEffect(() => {
        const authVal = localStorageService.getAuthDetails()
        if (authVal) {
            const isEmailFormat = JSON.parse(authVal)?.username?.includes("@")
            if(isEmailFormat){
                const userName = JSON.parse(authVal)?.username?.split("@")[0]
                setUserName(userName)
            }else{
                setUserName(JSON.parse(authVal).username);
            }
            
        }
    }, [loginModalToggle]);

    return(
        <header className={classNames(styles.header, "shadow-sm sticky-top bg-white pe-3")}>
            <section className='d-flex align-items-center'>
                <Sidebar /> 
                <Link className='ps-5' to={"/cumulative-analytics"} ><img width="145" className='ms-2' src={logo} alt="logo" /></Link>
                <ToastContainer />
            </section>
            { !login ?
            <Dropdown>
                <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
                    {userName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as="button" onClick={() => setChangePasswordToggle(true)}>
                        <FontAwesomeIcon icon={faKey} /><span className='ml-2'> Change Password</span>
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => onLogout(true)}>
                        <FontAwesomeIcon icon={faSignOut} /><span className='ml-2'> Logout</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            :
            <button className='btn btn-light px-4'>Login</button>
            }
            <Modal show={changePasswordToggle} style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}} dialogClassName={styles.modalWidth}>
                <Modal.Body className='d-flex flex-column justify-content-center align-items-center'>
                    <button className={styles.closeBtn} onClick={() => closeModal()}><FontAwesomeIcon icon={faClose} /></button>
                    <img src={logo} className="p-2" width="200" alt="MI logo"></img>
                    <hr className='w-100' />
                    <h4 className="text-center">Change password</h4>
                    <form className="px-3 py-2 w-100" onSubmit={handleSubmit}>
                        {invalidCredentials && <div className={styles.validators}><span>{invalidCredentials}</span></div>}
                        
                        <label htmlFor="oldPassword">Old Password</label>
                        <InputGroup className="mt-2">
                            <Form.Control
                                className={styles.customFormField}
                                type={oldPasswordToggle ? "text" : "password"}
                                aria-label="Old Password"
                                name="oldPassword"
                                value={passwordFormValues.oldPassword}
                                placeholder="Enter your old password"
                                onChange={event => handleChange(event)}
                                onBlur={event => handleValidation(event)}
                                aria-describedby="basic-addon2"
                            />                                
                            <InputGroup.Text>
                                {oldPasswordToggle ? <FontAwesomeIcon icon={faEye} onClick={() => setOldPasswordToggle(!oldPasswordToggle)} /> :  <FontAwesomeIcon icon={faEyeSlash} onClick={() => setOldPasswordToggle(!oldPasswordToggle)} />}
                            </InputGroup.Text>
                        </InputGroup>
                        <span className={styles.formErrors}>{passwordFormErrors.oldPassword}</span>
                        
                        <label htmlFor="newPassword">New Password</label>
                        <InputGroup className="mt-2">
                            <Form.Control
                                className={styles.customFormField}
                                type={newPasswordToggle ? "text" : "password"}
                                aria-label="New Password"
                                name="newPassword"
                                value={passwordFormValues.newPassword}
                                placeholder="Enter your new password"
                                onChange={event => handleChange(event)}
                                onBlur={event => handleValidation(event)}
                                aria-describedby="basic-addon2"
                            />                                
                            <InputGroup.Text>
                                {newPasswordToggle ? <FontAwesomeIcon icon={faEye} onClick={() => setNewPasswordToggle(!newPasswordToggle)} /> :  <FontAwesomeIcon icon={faEyeSlash} onClick={() => setNewPasswordToggle(!newPasswordToggle)} />}
                            </InputGroup.Text>
                        </InputGroup>
                        <span className={styles.formErrors}>{passwordFormErrors.newPassword}</span>

                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <InputGroup className="mt-2">
                            <Form.Control
                                className={styles.customFormField}
                                type={confirmPasswordToggle ? "text" : "password"}
                                aria-label="Confirm Password"
                                name="confirmPassword"
                                value={passwordFormValues.confirmPassword}
                                placeholder="Confirm your new password"
                                onChange={event => handleChange(event)}
                                onBlur={event => handleValidation(event)}
                                aria-describedby="basic-addon2"
                            />                                
                            <InputGroup.Text>
                                {confirmPasswordToggle ? <FontAwesomeIcon icon={faEye} onClick={() => setConfirmPasswordToggle(!confirmPasswordToggle)} /> :  <FontAwesomeIcon icon={faEyeSlash} onClick={() => setConfirmPasswordToggle(!confirmPasswordToggle)} />}
                            </InputGroup.Text>
                        </InputGroup>
                        <span className={styles.formErrors}>{passwordFormErrors.confirmPassword}</span>
                        {isPasswordLoading ? 
                            <button className='btn btn-info btnDefault w-100' disabled={isPasswordLoading}>
                                <span>Loading
                                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                </span>
                            </button>
                            :
                            <button className='btn btn-info btnDefault w-100' type="submit">Save Changes</button>}
                    </form>
                </Modal.Body>
            </Modal>
        </header>
    )
}

export default Header;