/**
 *
 * @version
 * @author: sandeep_k
 * @create date: 2020-01-19
 *
 * Functions to handle Local Storage Items
 */
const LocalStorageService = (function() {

  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setToken(tokenObj) {
    sessionStorage.setItem("rat_access_token", tokenObj.access_token);
    sessionStorage.setItem("rat_refresh_token", tokenObj.refresh_token);
  }

  function _getTokens() {
    return (
      sessionStorage.getItem("rat_access_token") &&
      sessionStorage.getItem("rat_refresh_token")
    );
  }

  function _getAccessToken() {
    return sessionStorage.getItem("rat_access_token");
  }

  function _getRefreshToken() {
    return sessionStorage.getItem("rat_refresh_token");
  }

  function _removeTokens() {
    sessionStorage.removeItem("rat_access_token");
    sessionStorage.removeItem("rat_refresh_token");
    sessionStorage.removeItem("rat_auth_details");
  }

  function _customHeaders() {
    return { token: _getAccessToken() };
  }

  function _setAuthDetails(auth_details) {
    sessionStorage.setItem("rat_auth_details", auth_details);
  }

  function _getAuthDetails() {
    return sessionStorage.getItem("rat_auth_details");
  }

  //Cookies
  function _setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  function _getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  function _checkCookie(cname) {
    let user = _getCookie(cname);
    if (user !== "") {
      return true;
    } else {
      _setCookie(cname, "guest", 365);
      return false;
    }
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getTokens: _getTokens,
    setAuthDetails: _setAuthDetails,
    getAuthDetails: _getAuthDetails,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    removeTokens: _removeTokens,
    customHeaders: _customHeaders,
    getCookie: _getCookie,
    setCookie: _setCookie,
    checkCookie: _checkCookie
  };
})();

export default LocalStorageService;
/* Function to handle Local Storage Items */
