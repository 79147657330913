import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import TooltipButton from "../../../TooltipButton/TooltipButton";
import { averageCitationMessage } from "../../../TooltipButton/TooltipMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { title, toTitleCase } from "../../../Filter/Filter";
import * as d3 from "d3";
import { useDispatch } from "react-redux";
import { SpinnerLoader } from "../../../Loader/Loader";
import Graphdownload from "../Graphdonwload/Graphdownload";

import "./MultiLineForAverageCitation.scss";
import NoDataFound from "../../../NotFound/NoDataFound";

function loadGraph(data, dispatch) {


  let width = 1200,
    height = 500,
    marginX = 90,
    marginY = 40,
    duration = 250;

    var element = d3.select(".chart-container").node();
    const compWidth = element.getBoundingClientRect().width;

  let svgWidth = width;

  if (compWidth > 800) {
    svgWidth = compWidth;
  }

  const svgHeight = height + marginY;

  const legendSymbols = [
    "M -4 0 A 4 4 0 1 0 4 0 A 4 4 0 1 0 -4 0 Z",
    "M 0 -4 L 4 0 L 0 4 L -4 0 Z",
    "M -4 -4 L 4 -4 L 4 4 L -4 4 Z",
    "M 0.00 -5.33 L 4.00 2.67 L -4.00 2.67 Z",
    "M -4 -2 L 4 -2 L 0 6 Z",
  ];


  const coordinateArray = [];

  const lineOpacity = "1",
    lineOpacityHover = "1",
    otherLinesOpacityHover = "0.1",
    lineStroke = "2px",
    lineStrokeHover = "3.5px";

  const circleOpacity = "1",
    circleOpacityOnLineHover = "1",
    otherCircleOpacityHover = "0.1",
    circleRadius = 3,
    circleRadiusHover = 6;

  const legendRectSize = 16,
    legendSpacing = 4,
    gapBetweenGroups = 200,
    spaceForLabels = 20;

  let spaceForLegend = 300;

  width = svgWidth - marginX - spaceForLegend;

 

  if (!d3.select("#multi_line > svg").empty()) {
    d3.select("#multi_line > svg").remove();
  }

  /* Scale */
  let xScale = d3
    .scalePoint()
    .domain(data.categories)
    .range([0, width])
    .padding(0.5);

  let max = data.series.map((d) => {
    return d3.max(d.data);
  });

  let yScale = d3
    .scaleLinear()
    .domain([0, Math.ceil(d3.max(max))])
    .range([height - marginY, 0])
    .nice(3);

  let color = d3.scaleOrdinal(d3.schemeCategory10);


  /* Add SVG */
  let svg = d3
    .select("#multi_line")
    .append("svg")
    .attr("viewBox", `0 0  ${svgWidth} ${svgHeight}`)
    // .attr("width", svgWidth)
    // .attr("height", svgHeight)
    .append("g")
    .attr("transform", `translate(${marginX}, ${marginY - 30 })`);


  /* Add Axis into SVG */
  let xAxis = d3.axisBottom(xScale);
  let yAxis = d3.axisLeft(yScale).ticks(5);

  const removeYAxis = svg
    .append("g")
    .attr("class", "y axis")
    .call(yAxis)
    .attr("font-size", "14px");

  svg
    .append("text")
    .attr("class", "y label")
    .attr("text-anchor", "end")
    .attr("x", -((height - marginY * 4) / 2))
    .attr("y", -(marginX + marginY) / 2)
    .attr("dy", ".75em")
    .attr("transform", "rotate(-90)")
    .text("Average Citation");

  removeYAxis.selectAll("path,line").remove();

  const yAxisGrid = yAxis.tickSize(-width).tickFormat("").ticks(5);
  const removeYAxisGrid = svg
    .append("g")
    .attr("class", "y axis-grid")
    .attr("color", "#e6e6e6")
    .call(yAxisGrid);

  removeYAxisGrid.selectAll("path").remove();

  const removeXAxis = svg
    .append("g")
    .attr("class", "x axis")
    .attr("transform", `translate(0, ${height - marginY})`)
    .call(xAxis)
    .attr("font-size", "14px");

  removeXAxis.selectAll("line").remove();

  /* Add line into SVG */
  let line = d3
    .line()
    .x((d, i) => xScale(data.categories[i]))
    .y((d) => yScale(d))
    .curve(d3.curveMonotoneX);

  let lines = svg.append("g").attr("class", "lines");

  const nameIndex = d3.local();

  lines
    .selectAll(".line-group")
    .data(data.series)
    .enter()
    .append("g")
    .attr("class", (d, i) => `line-group line-${i + 1}`)
    .each(function (d, i) {
      nameIndex.set(this, i);
    })
    .on("mouseover", function (event, d) {
    })
    .on("mouseout", function (d) {
      //   svg.select(".title-text").remove();
    })
    .append("path")
    .attr("class", "line")
    .attr("index", (d, i) => `line-${i + 1}`)
    .attr("fill", "none")
    .attr("stroke-width", lineStroke)
    .attr("d", (d) => line(d.data))
    .style("stroke", (d, i) => color(i))
    .style("opacity", lineOpacity)
    .on("mouseover", function (e, d) {
      d3.selectAll(".line").style("opacity", otherLinesOpacityHover);
      // d3.selectAll(".circle").style("opacity", circleOpacityOnLineHover);
      d3.selectAll("[index ^=line]").style("opacity", otherCircleOpacityHover);
      d3.selectAll(`[index =${d3.select(this).attr("index")}]`).style(
        "opacity",
        circleOpacityOnLineHover
      );
      // console.log("d3.select(this).attr('index')", d3.select(this).attr('index'));
      d3.select(this)
        .style("opacity", lineOpacityHover)
        .style("stroke-width", lineStrokeHover)
        .style("cursor", "pointer");

      //legend hover effect
      d3.selectAll(`#legend [class*="line"]`).style(
        "opacity",
        otherLinesOpacityHover
      );
      d3.select(`#legend .${d3.select(this).attr("index")}`).style(
        "opacity",
        lineOpacityHover
      );
    })
    .on("mouseout", function (d) {
      // d3.selectAll(".circle").style("opacity", circleOpacity);
      d3.selectAll("[index ^=line]").style("opacity", circleOpacity);
      d3.selectAll(".line").style("opacity", lineOpacity);
      d3.select(this).style("stroke-width", lineStroke).style("cursor", "none");
      d3.selectAll(`#legend [class*="line"]`).style("opacity", lineOpacity);
    });

  const valueIndex = d3.local();
  const lineIndex = d3.local();
  /* Add circles in the line */
  lines
    .selectAll("circle-group")
    .data(data.series)
    .enter()
    .append("g")
    .style("fill", (d, i) => color(i))
    .style("opacity", circleOpacity)
    .attr("class", "point-group")
    .attr("index", (d, i) => `line-${i + 1}`)
    .attr("Index", (d, i) => i)
    .each(function (d, i) {
      lineIndex.set(this, i);
    })
    .selectAll("circle")
    .data((d) => d.data)
    .enter()
    .append("g")
    .attr("class", "circle")
    .each(function (d, i) {
      valueIndex.set(this, i);
    })
    .on("mouseover", function (event, d) {
      d3.select(this)
        .style("cursor", "pointer")
        .append("text")
        .attr("class", "text");


      
      let height = yScale(d); 
      let tooltipWidth = 150;
      let tooltipHeight = 35;
      let center_x = spaceForLabels; //+  x(d)
      let center_y = height;

      const tooltipGroup = d3
        .select("#multi_line svg")
        .append("g")
        .attr("id", "tooltipGroup");



      tooltipGroup
        .append("rect")
        .attr("width", tooltipWidth)
        .attr("height", tooltipHeight)
        .attr("fill", "white")
        .attr("id", "rectBackground")
        // .attr("x", spaceForLabels + x(d))
        .attr("y", -tooltipHeight / 2 + 4)
        .attr("rx", "5")
        .style("box-shadow", "1px 1px 5px 1px black")
        .style("filter", "drop-shadow(0px 0px 3px rgba(0, 0, 0, 1)");

      tooltipGroup
        .append("text")
        .attr("id", "tooltip_upper_text")
        .attr("x", 7)
        // .attr('y', +height  )
        .attr("font-size", 10)
        .text(data.categories[valueIndex.get(this)]);

      let lowerText = tooltipGroup
        .append("text")
        // .attr("id", "tooltip")
        .attr("x", 7)
        .attr("y", +15);

      lowerText
        .append("tspan")
        .attr("font-size", 18)
        .attr("font-weight", "bold")
        .attr("fill", color(lineIndex.get(this)))
        .text("• ");

      lowerText
        .append("tspan")
        .attr("id", "tooltip_lower_text")
        .attr("font-size", 12)
        .text(data.series[lineIndex.get(this)].name);

      lowerText
        .append("tspan")
        .attr("font-size", 12)
        .attr("font-weight", "bold")
        .text(`: ${d}`);

      let upperTextElement = document.getElementById("tooltip_upper_text");
      let lowerTextElement = document.getElementById("tooltip_lower_text");
      let upperTextBoundingBox = upperTextElement.getBBox();
      let lowerTextBoundingBox = lowerTextElement.getBBox();

      // Get the dimensions of the bounding box
      let upperTextWidth = upperTextBoundingBox.width;
      // var upperTextHeight = upperTextBoundingBox.height;

      let lowerTextWidth = lowerTextBoundingBox.width;
      // var lowerTextHeight = lowerTextBoundingBox.height;

      let textWidth =
        upperTextWidth > lowerTextWidth + 50
          ? +upperTextWidth
          : +lowerTextWidth + 50;

      // Set the width and height of the background rectangle
      let rectBackground = document.getElementById("rectBackground");
      let tooltipGroupBackground = document.getElementById("tooltipGroup");
      rectBackground.setAttribute("width", textWidth + 20); // Add padding for better visibility
      // rectBackground.setAttribute("height", textHeight + 10);
      // let shiftRight = +d3.select(this).select('circle').attr('cx') + marginX - rectBackground.getAttribute("width")/2
      let shiftRight =
        xScale(data.categories[valueIndex.get(this)]) +
        marginX -
        rectBackground.getAttribute("width") / 2;
      if (shiftRight < 0) {
        shiftRight = -shiftRight + 10;
      }
      let position_x =
        document.getElementById("multi_line").offsetWidth -
          (+xScale(data.categories[valueIndex.get(this)]) +
            marginX -
            rectBackground.getAttribute("width") / 2) >
        textWidth
          ? +xScale(data.categories[valueIndex.get(this)]) +
              marginX -
              rectBackground.getAttribute("width") / 2 >
            0
            ? +xScale(data.categories[valueIndex.get(this)]) +
              marginX -
              rectBackground.getAttribute("width") / 2
            : +xScale(data.categories[valueIndex.get(this)]) +
              marginX -
              rectBackground.getAttribute("width") / 2 +
              shiftRight
          : +xScale(data.categories[valueIndex.get(this)]) +
            marginX -
            rectBackground.getAttribute("width");

      // let position_x = +d3.select(this).select('circle').attr('cx') + marginX
      let position_y =
        height > rectBackground.getAttribute("height") / 2
          ? height
          : rectBackground.getAttribute("height") / 2;
          
      tooltipGroupBackground.setAttribute(
        "transform",
        `translate(${position_x},${position_y + 40})`
      );
    })
    .on("mouseout", function (d) {
      d3.select(this)
        .style("cursor", "none")
        .transition()
        .duration(duration)
        .selectAll(".text")
        .remove();

      //removing tooltip
      d3.select("#tooltip").remove();

      d3.select("g#tooltipGroup").remove();
    })
   
    .append("path")
    //.attr("index", (d, i, n) => +n[i].parentNode.parentNode.getAttribute('Index') + i)
    .attr("d", (d, i, n) => {
    
      let index =
        n[i].parentNode.parentNode.getAttribute("Index") % legendSymbols.length;
      n[i].parentNode.setAttribute("Height", yScale(d));

      if (i == data.categories.length - 1) {
        n[i].parentNode.parentNode.setAttribute("Index", "");
      }
      let x_coordinate = xScale(data.categories[i]);
      let y_coordinate = yScale(d);
      coordinateArray.push({ x_coordinate, y_coordinate });
      return legendSymbols[index];
    })
    .attr(
      "transform",
      (d, i) =>
        `translate(${xScale(data.categories[i])}, ${yScale(d)}) scale(1,1)`
    )
    // .style("opacity", circleOpacity)
    .style("stroke", "#fff")
    .style("stroke-width", "1")
    .on("mouseover", function (d) {
    

      // d3.selectAll(".circle").style("opacity", circleOpacityOnLineHover);
      d3.selectAll("[index ^=line]").style("opacity", otherCircleOpacityHover);
      // d3.selectAll(".line[index ^=line]").style("opacity", otherLinesOpacityHover);
      d3.selectAll(
        `[index =${d3.select(this.parentNode.parentNode).attr("index")}]`
      ).style("opacity", circleOpacityOnLineHover);
      d3.selectAll(
        `.line[index =${d3.select(this.parentNode.parentNode).attr("index")}]`
      ).style("stroke-width", lineStrokeHover);
      // d3.selectAll(this).style("opacity", 1)

      d3.select(this)
        .transition()
        .duration(1)
        .attr(
          "transform",
          d3.select(this).attr("transform").replace("scale(1,1)", "scale(2,2)")
        )
        .style("stroke-width", "2");
   
    })
    .on("mouseout", function (d) {
      // d3.selectAll(".circle").style("opacity", circleOpacity);
      d3.selectAll("[index ^=line]").style("opacity", circleOpacity);
      d3.selectAll(".line")
        .style("opacity", lineOpacity)
        .style("stroke-width", lineStroke);
      // d3.select(this).style("stroke-width", lineStroke).style("cursor", "none");

      // d3.select(this).transition().duration(duration).attr("r", circleRadius);
      d3.select(this)
        .transition()
        .duration(1)
        .attr(
          "transform",
          d3.select(this).attr("transform").replace("scale(2,2)", "scale(1,1)")
        )
        .style("stroke-width", "1");
   
    });
  

  let legend = svg
    .append("g")
    .attr("id", "legend")
    .attr(
      "transform",
      `translate(${svgWidth - marginX - legendRectSize - spaceForLegend + 30},0)`
    )
    .selectAll(".legend")
    .data(data.series)
    .enter()
    .append("g")
    .attr("class", (d, i) => `legend-${i + 1} line-${i + 1}`)
    .attr("transform", function (d, i) {
      let height = legendRectSize + legendSpacing;
      let offset = -gapBetweenGroups / 2;
      // let horz = spaceForLabels + width + 40 - legendRectSize;
      let horz = 0;
      let vert = i * height - offset;
      return "translate(" + horz + "," + vert + ")";
    });

  let legendIndex = 0;

  legend
    .append("g")
    .attr("transform", `translate(6,7)`)
    .append("path")
    // .attr('d', 'M 1 13 L 15 13')
    .attr("d", "M -7 0 L 7 0")
    .style("fill", "none")
    .style("stroke-width", "2")
    .style("stroke-linecap", "round")
    .style("stroke", function (d, i) {
      return color(i);
    });

  legend
    .select("g")
    .append("path")
    .attr("d", (d, i) => {
      legendIndex = legendIndex === i ? legendIndex : legendIndex + 1;
      if (legendIndex > legendSymbols.length - 1) {
        legendIndex = 0;
      }
      return legendSymbols[legendIndex];
    })
    .style("fill", function (d, i) {
      return color(i);
    })
  
    .style("stroke", "#fff")
    .style("stroke-width", "1")
    .style("opacity", "1");

  legend
    .append("text")
    .attr("class", "legend")
    .attr("x", legendRectSize + legendSpacing)
    .attr("y", legendRectSize - legendSpacing)
    .text((d) => trimText(d.name, 35))
    .style("font", "14px sans-serif")
    .append("title")
    .text((d) => d.name);

  function trimText(text, threshold) {
    if (text.length <= threshold) return text;
    return text.substr(0, threshold).concat("...");
  }

  return d3.select("#multi_line > svg").node();
}

function MultiLineForAverageCitation({ data, loader, setLoader, loading }) {
  const [chartData, setChartData] = useState({});
  const [svg, setSvg] = useState("");
  const dispatch = useDispatch();
  const [validContnet, setValidContent] = useState(true);

  useEffect(() => {
    if (Object.keys(data).length) {
      setChartData(data);
      setSvg(loadGraph(data));
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(chartData).length) {
      if (chartData.series && chartData.series.length >= 1) {
        setValidContent(true);
        setSvg(loadGraph(chartData, dispatch));
      } else {
        setValidContent(false);
      }
    }
  }, [chartData]);

  return (
    <div className="my-3 card card-body mainChartMultCitaiLine">
      <Row>
        <Col md={6}>
          <h5>
            Average Citation
            <TooltipButton
              tooltipMessage={averageCitationMessage}
              placement="right"
              type={"string"}
              classes={"TooltipDiv"}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="sm"
                className="fs-14 mx-1 text-secondary"
              />
            </TooltipButton>
          </h5>
        </Col>
      </Row>
      <div className="mt-3 position-relative">
        {loader ? (
          <div className="loaderSection">
            <SpinnerLoader size="lg" />
          </div>
        ) : (
          <>
            {!validContnet && <NoDataFound />}

            {svg && <Graphdownload svg={svg} filename={toTitleCase(title)} />}
            <div className="chart-container">
              <div
                id="multi_line"
                className=""
              ></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default MultiLineForAverageCitation;
