import React, { useEffect, useState } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import "./ModalDetails.scss"
import classNames from 'classnames'

const ModalDetails = ({show, handleClose, handleChange, data, headers}) => {
    const [accepting, setAccepting] = useState([])
    const [rejecting, setRejecting] = useState([])

    function sortPopuRank() {
        headers?.map(h=>{
            if(h.popupType ==="accepting"){
                setAccepting(prev => [...prev, {
                    field: h.field,
                    value: data[h.field],
                    rank:  h.popupRank,
                    display: h.display,
                    dataType: h.dataType
                }])
            }else if(h.popupType ==="rejecting"){
                setRejecting(prev => [...prev, {
                    field: h.field,
                    value: data[h.field],
                    rank:  h.popupRank,
                    display: h.display,
                    dataType: h.dataType
                }])
            }
        })
    }

    useEffect(()=>{
        sortPopuRank()
    },[])

  return (
    <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header className='d-flex justify-content-between align-items-start'>
          <Modal.Title>
            <h5>Manuscript Info</h5>
            <p className='text-muted mb-1 h6 fw-normal'>{`Manuscript Id: ${data.s_manuscript_id}`}</p>
          </Modal.Title>
          <div className='text-danger fs-12 cursor-pointer' onClick={()=> handleClose()}><FontAwesomeIcon icon={faXmark} /> Close </div>
        </Modal.Header>
        <Modal.Body className='px-3 py-1'>
            <Tabs
            defaultActiveKey="rejectedInfo"
            id="justify-tab-example"
            className="mb-3 customTabStyle"
            justify
            >
                <Tab eventKey="rejectedInfo" title="Rejecting Info" tabClassName={classNames("customTab")} >
                   
                    <div>
                        {rejecting?.sort((a,b)=> a.rank- b.rank)?.map(d => (
                            <div>
                                <label className='fw-bold'>{d?.display}</label>
                                <p className=''>{d.value || "NA"}</p>
                            </div>
                        ))}
                    </div>
                </Tab>
                {data?.status !=="unresolved" && <Tab eventKey="publishedInfo" title="Accepting Info" tabClassName='customTab'>
                    <div>
                        {accepting?.sort((a,b)=> a.rank- b.rank)?.map(d => (
                            <div>
                                <label className='fw-bold'>{d?.display}</label>
                               
                               <p key={d.value}>
                               { 
                               d.field === "p_doi" && d.dataType==="url" ?
                                    <a
                                href={d.value &&`https://doi.org/${d.value}`}
                                target="_blank"
                                className={d.value? "linkText": "text-secondary"+ " flex-box"}
                                >
                                <span className="text-break text-break-sm">
                                    <span>{d.value }</span>
                                </span>
                                </a>:
                                <span>{d.value || "0"}</span>}
                             </p>
                            </div>
                        ))}
                    </div>
                </Tab>}
            </Tabs>
        </Modal.Body>
      </Modal>
  )
}

export default ModalDetails
