import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./JournalTableDetailsDoubleHeader.scss";

const TableHeadItem = ({
  headingData,
  rowSpan,
  colSpan,
  dataKey,
  handleChange,
  sort,
}) => {
  const handleSort = (order, data) => {
    const field = data.sortKey
      ? data.field !== data?.sortKey
        ? data?.sortKey
        : headingData?.field
      : headingData.field;
    handleChange(order, field);
  };

  return headingData?.display && headingData.tableDisplay ? (
    <th
      key={dataKey}
      className="align-middle text-nowrap"
      rowSpan={rowSpan || 1}
      colSpan={colSpan || 1}
    >
      {headingData.sortable ? (
        <>
          {(sort.field === headingData.field ||
            sort.field === headingData.sortKey) &&
          sort.order === "desc" ? (
            <div
              className="d-flex justify-content-between align-items-center cursor-pointer"
              onClick={() => handleSort("asc", headingData)}
            >
              {headingData.display}
              <FontAwesomeIcon className="ms-3" icon={faSortUp} />
            </div>
          ) : (sort.field === headingData.field ||
              sort.field === headingData.sortKey) &&
            sort.order === "asc" ? (
            <div
              className="d-flex justify-content-between align-items-center cursor-pointer"
              onClick={() => handleSort("desc", headingData)}
            >
              {headingData.display}
              <FontAwesomeIcon className="ms-3" icon={faSortDown} />
            </div>
          ) : (
            <div
              className="d-flex justify-content-between align-items-center cursor-pointer"
              onClick={() => handleSort("asc", headingData)}
            >
              {headingData.display}
              <FontAwesomeIcon className="ms-3" icon={faSort} />
            </div>
          )}
        </>
      ) : (
        <div
          className={`d-flex  align-items-center ${
            headingData.display == "REJECTING JOURNAL"
              ? ""
              : "justify-content-center"
          }`}
        >
          {headingData.display}
        </div>
      )}
    </th>
  ) : (
    ""
  );
};

export default TableHeadItem;
