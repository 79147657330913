import React from 'react'
import {Table} from "react-bootstrap"
// import "./TableDetails.scss"
import TableHeadItem from '../ManuscriptTable/TableHeadItem'
import { useDispatch, useSelector } from 'react-redux'
import { setGraphType, setManuscriptModal } from '../../../Redux/Action/filter.action'

const ExpandableTableDetails = ({parent, tableData, headers, start, handleSort, sort, graphType}) => {
    return (
    <Table responsive className='tableBlock table-hover mt-2 mb-0'>
        <thead className='text-uppercase'>
            <tr className='align-middle' key={"No"}>
                <th width="7%">No.</th>
                {headers?.map((h, idx)=> (
                    <TableHeadItem headingData={h} dataKey={idx} 
                    handleChange={handleSort} sort={sort}
                    />
                ))}
            </tr>
        </thead>
        <tbody>
           
               {tableData?.map((data, idx)=> (
                    <tr className='align-middle' key={idx}>
                        <td className='py-3' key={idx}><span className='idxStyle'>{start+idx+1}</span></td>
                        {headers?.map((h, idx)=>(
                        <TableItem rowData={data} headerDetails={h} dataKey={idx} parent={parent} childHeader={headers} graphType={graphType} />
                        ))
                        }
                    </tr>
                ))}
            
        </tbody>
    </Table>
  )
}

const TableItem =({rowData, headerDetails, dataKey, parent, childHeader, graphType}) => {
    const dispatch = useDispatch()
    const {
      accessType,
      searchValue,
      acceptingJournal,
      rejectingJournal,
      publishers,
    } = useSelector((state) => state.allFilters);
   
    const handleGridClick =(columnName) => {
    const parentField = parent?.header[0]?.field
    const childField = childHeader[0]?.field
    let obj ={}
    obj[childField] = rowData[childField]
    obj["filterStatus"]=  columnName == "transferCount" && accessType == true ? "oa" : columnName;
    obj[parentField]=parent?.data[parentField]
    obj["graphType"]= "publisherTrend"
    dispatch(setGraphType({
        title: `${parent?.data[parentField]} > ${rowData[childField]} > ${headerDetails.display}`,
        graphType: obj
      }))
    dispatch(setManuscriptModal(true))
    }
    return(
        <>
        <td key={dataKey}>{headerDetails?.dataType ==="url"  && rowData[headerDetails?.field]!==0
        ? <>
        <span className='linkText cursor-pointer' onClick={()=> handleGridClick(headerDetails?.field)}>
          {rowData[headerDetails?.field] || "NA"}
        </span>
        </>
        : <div>{rowData[headerDetails?.field] }</div>}
        </td>
       
      </>
    )
}

export default ExpandableTableDetails
