import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import { uid } from "./uid";
import ReactGA from "react-ga4";
import { title, toTitleCase } from "../../../Filter/Filter";
import { useDispatch } from "react-redux";
import {
  setGraphType,
  setManuscriptModal,
} from "../../../../Redux/Action/filter.action";
import Graphdownload from "../Graphdonwload/Graphdownload";

import "./Treemap.scss";
import NoDataFound from "../../../NotFound/NoDataFound";

const loadGraph = (data, dispatch, graphType) => {
  let width = 1200;
  let svgHeight = 650;
  let legendHeight = 120;
  let height = svgHeight - legendHeight;

  if (!d3.select("#tree_map > svg").empty()) {
    d3.select("#tree_map > svg").remove();
  }

  var element = d3.select(".chart-container_tree_map").node();

  if (element) {
    const compWidth = element.getBoundingClientRect().width;
    width = compWidth < width ? width : compWidth;
  }

  const colorArray = [
    "#9C27B0",
    "#2196F3",
    "#009688",
    "#CDDC39",
    "#FF9800",
    "#4CAF50",
    "#E91E63",
    "#F44336",
    "#795548",
    "#607D8B",
  ];
  const color = d3.scaleOrdinal(colorArray);

  // This custom tiling function adapts the built-in binary tiling function
  // for the appropriate aspect ratio when the treemap is zoomed-in.
  function tile(node, x0, y0, x1, y1) {
    d3.treemapBinary(node, 0, 0, width, height);
    for (const child of node.children) {
      child.x0 = x0 + (child.x0 / width) * (x1 - x0);
      child.x1 = x0 + (child.x1 / width) * (x1 - x0);
      child.y0 = y0 + (child.y0 / height) * (y1 - y0);
      child.y1 = y0 + (child.y1 / height) * (y1 - y0);
    }
  }

  // Compute the layout.
  const hierarchy = d3
    .hierarchy(data)
    .sum((d) => {
      if (d.children) {
        delete d.value;
        return d;
      }

      return d.value;
    })
    .sort((a, b) => b.value - a.value);

  const root = d3.treemap().tile(tile)(hierarchy);

  // Create the scales.
  const x = d3.scaleLinear().rangeRound([0, width]);
  const y = d3.scaleLinear().rangeRound([0, height]);

  // Create the SVG container.
  const svg = d3
    .select("#tree_map")
    .append("svg")
    // .attr("viewBox", [0.5, -30.5, 0, height + 30])
    .attr("width", 0)
    .attr("height", svgHeight)
    .attr("style", "max-width: 100%; height: auto;");
  //.style("font", "10px sans-serif");

  svg
    .transition()
    .duration(1200)
    // .attr("viewBox", [0.5, -60.5, width, svgHeight])
    .attr("width", width);

  //Breadcrumbs
  let breadCrumbs = svg
    .append("g")
    .attr("class", "breadcumb")
    .attr("transform", `translate(0, 20)`);

  // Display the root.
  let group = svg
    .append("g")
    .attr("transform", `translate(0, 20)`)
    .attr("class", "groups")
    .call(render, root);

  //Legend
  let legend = svg
    .append("g")
    .attr("transform", `translate(0, 20)`)
    .attr("id", "legend");

  let legendGroup = legend
    .selectAll("rect.legend")
    .data(hierarchy.children)
    // .data(data.children)
    .enter()
    .append("g")
    .attr("transform", (d, i) => {
      if (i + 1 > data.children.length / 2) {
        return `translate(${width / 2 + 100},${
          height + 17 * (i + 1) - (data.children.length / 2) * 17
        })`;
      }
      return `translate(${width / 2 - 450},${height + 17 * (i + 1)})`;
    });

  legendGroup
    .append("rect")
    .attr("width", 12)
    .attr("height", 12)
    .attr("rx", 6)
    .attr("ry", 6)
    .attr("fill", (d, i) => colorArray[i]);

  legendGroup
    .append("text")
    .attr("fill", "black")
    .attr("x", 20)
    .attr("y", 10)
    .style("font-size", "14px")
    .text((d) => trimText(d.data.name, 70))
    // .text((d) => trimText(d.name, 70))
    .append("title")
    .text((d) => d.data.name);
  // .text((d) => d.name);

  function trimText(text, threshold) {
    if (text.length <= threshold) return text;
    return text.substr(0, threshold).concat("...");
  }

  //child tree
  function childTree() {
    hierarchy.children.map((item, index) => {
      let childHierarchy = d3
        .hierarchy(data.children[index])
        .sum((d) => d.value)
        .sort((a, b) => b.value - a.value);

      let childRoot = d3
        .treemap()
        .size([x(item.x1) - x(item.x0), y(item.y1) - y(item.y0)])
        .padding(1)(childHierarchy);

      group
        .select(`.group_${index + 1}`)
        .append("g")
        .attr("class", `nested_child_group_${index + 1}`)
        .selectAll(`.group_${index + 1}`)
        .data(childRoot.children)
        .enter()
        .append("rect")
        .attr("fill", colorArray[index])
        .attr("width", (d) => d.x1 - d.x0)
        .attr("height", (d) => d.y1 - d.y0)
        .attr("x", (d) => d.x0)
        .attr("y", (d) => d.y0);

      const label = group.select(`.group_${index + 1}`);

      labelFormate(label, item);
    });
  }

  function labelFormate(label, item) {
    const text = label
      .append("g")
      .append("text")
      .attr("x", (d) => d.x0 + (x(item.x1) - x(item.x0)) / 2)
      .attr("y", (d) => d.y0 + (y(item.y1) - y(item.y0)) / 2)
      .attr("font-weight", "bold")
      .attr("font-size", "12px")
      .style("fill", "blank")
      .style("stroke", "white")
      .style("stroke-width", "0.5em")
      .style("paint-order", "stroke")
      .style("stroke-linejoin", "round")
      .attr("dy", "1.2em")
      .attr("text-anchor", "middle")
      .style("white-space", "nowrap")
      .style("overflow", "hidden")
      .style("text-overflow", "ellipsis")
      .attr("width", (d) => x(item.x1) - x(item.x0))
      .text((d) => {
        let textLength = d.data.name.length * 12 * 0.44;
        let width = x(item.x1) - x(item.x0);

        if (textLength > width - 30) {
          let name = d.data.name;
          let length = Math.floor((width - 50) / (12 * 0.44));
          let newName = name.substr(0, length);
          return newName + "...";
        }
        return d.data.name;
      });
  }

  childTree();

  function render(group, root) {
    const node = group
      .selectAll("g")
      .data(root.children)
      .join("g")
      .attr("class", (d, i) => `group_${i + 1}`);

    node
      .filter((d) => (d === root ? d.parent : d.children))
      .attr("cursor", "pointer")
      .on("click", (event, d) => {
        return d === root ? zoomout(root) : zoomin(d);
      });

    node
      .append("rect")
      .attr("id", (d) => (d.leafUid = uid("leaf")).id)
      .attr("fill", (d) => {
        while (d.depth > 1) d = d.parent;
        return color(d.data.name);
      })
      .attr("fill-opacity", (d) => {
        if (d.depth > 1 && !d.data?.children) {
          return 1;
        }
        return 0;
      })
      .attr("class", (d, i) => {
        if (d.depth > 1 && !d.data?.children) {
          return `child_${i + 1}`;
        }
        return `parent_${i + 1}`;
      })
      .style("cursor", "pointer")
      .on("click", function (e, d) {
        if (d.depth > 1 && !d.data?.children) {
          ReactGA.event({
            category: toTitleCase(title),
            action: `Tree Map Graph Clicked`,
            label: `${
              graphType === "categoriesGraph" ? "subject_category" : "p_journal"
            }= "${d.data.name}", ${
              graphType === "categoriesGraph" ? "p_journal" : "s_journal"
            }= "${d.parent.data.name}", filterStatus= "totalPubication",`,
          });

          dispatch(
            graphType === "categoriesGraph"
              ? setGraphType({
                  title: `${d.data.name} > ${d.parent.data.name} > TOTAL PUBLICATIONS`,
                  graphType: {
                    graphType: "subjectCascade",
                    subject_category: d.data.name,
                    p_journal: d.parent.data.name,
                    filterStatus: "totalPubication",
                  },
                })
              : setGraphType({
                  title: `${d.data.name} > ${d.parent.data.name} > TOTAL PUBLICATIONS`,
                  graphType: {
                    graphType: "journalCascade",
                    p_journal: d.data.name,
                    s_journal: d.parent.data.name,
                    filterStatus: "totalPubication",
                  },
                })
          );
          dispatch(setManuscriptModal(true));
        }
      });

    node
      .append("clipPath")
      .attr("id", (d) => (d.clipUid = uid("clip")).id)
      .append("use")
      .attr("xlink:href", (d) => d.leafUid.href);

    node
      .append("text")
      .attr("clip-path", (d) => d.clipUid)
      .attr("font-weight", (d) => (d === root ? "bold" : null))
      .attr("id", (d, i) => `child_text_${i + 1}`)
      .attr("x", (d) => (x(d.x1) - x(d.x0)) / 2)
      .attr("y", (d) => (y(d.y1) - y(d.y0)) / 2)
      .attr("font-weight", "bold")
      .attr("font-size", "12px")
      .style("fill", "blank")
      .style("stroke", "white")
      .style("stroke-width", "0.5em")
      .style("paint-order", "stroke")
      .style("stroke-linejoin", "round")
      .attr("dy", "1.2em");

    node
      .on("mouseover", function (event, d) {
        if (d.depth > 0) {
          let text_x;
          let text_y;

          let tooltipPointMargin = 5;
          let center_x = x(d.x0) + (x(d.x1) - x(d.x0)) / 2;
          let center_y = y(d.y0) + (y(d.y1) - y(d.y0)) / 2 - tooltipPointMargin;

          const tooltipGroup = d3
            .select("#tree_map svg .groups")
            .append("g")
            .attr("id", "tooltipGroup");

          tooltipGroup
            .append("rect")
            .attr("width", "5")
            .attr("height", "5")
            .attr("fill", "white")
            .attr("id", "rectBackground")
            .attr("x", "5")
            .attr("y", "5")
            .attr("rx", "5")
            .style("filter", "drop-shadow(0px 0px 3px rgba(0, 0, 0, 1)");

          tooltipGroup
            .append("path")
            .attr(
              "d",
              `M${center_x},${center_y} L${center_x + 15},${center_y - 20} L${
                center_x - 15
              },${center_y - 20} Z`
            )
            .style("fill", "white");

          const tspan = tooltipGroup
            .append("text")
            .attr("id", "tooltip")
            .attr("x", () => {
              text_x = x(d.x0) + (x(d.x1) - x(d.x0)) / 2;
              return text_x;
            })
            .attr("y", () => {
              text_y = y(d.y0) + (y(d.y1) - y(d.y0)) / 2 - 40;
              return text_y;
            })
            .attr("font-weight", "bold")
            .attr("font-size", "14px")
            .style("fill", "blank")
            .style("stroke", "white")
            .style("stroke-width", "0.5em")
            .style("paint-order", "stroke")
            .style("stroke-linejoin", "round")
            .attr("dy", "1.2em")
            .text(() => `${d.data.name}: ${d.value}`)
            .attr("text-anchor", "middle");

          var textElement = document.getElementById("tooltip");
          var textBoundingBox = textElement.getBBox();

          // Get the dimensions of the bounding box
          var textWidth = textBoundingBox.width;
          var textHeight = textBoundingBox.height;

          // Set the width and height of the background rectangle
          var rectBackground = document.getElementById("rectBackground");
          rectBackground.setAttribute("width", textWidth + 20); // Add padding for better visibility
          rectBackground.setAttribute("height", textHeight + 10);
          rectBackground.setAttribute("x", text_x - textWidth / 2 - 10);
          rectBackground.setAttribute("y", text_y - textHeight / 2 + 5);

          if (text_x + textWidth / 2 > width) {
            textElement.setAttribute("text-anchor", "end");
            textElement.setAttribute("x", width - 10);

            rectBackground.setAttribute("x", width - textWidth - 20);
          }

          if (text_x - textWidth / 2 <= 0) {
            textElement.setAttribute("text-anchor", "start");
            textElement.setAttribute("x", 10);

            rectBackground.setAttribute("x", 0);
          }

          d3.select(this)
            .select("rect")
            .attr("fill-opacity", (d) => {
              if (d.depth > 1 && !d.data?.children) {
                return 1;
              }
              return 0.5;
            });
        }
      })
      .on("mouseout", function (d) {
        d3.select("g#tooltipGroup").remove();

        d3.select(this)
          .select("rect")
          .attr("fill-opacity", (d) => {
            if (d.depth > 1 && !d.data?.children) {
              return 1;
            }
            return 0;
          });
      });

    group.call(position, root);
  }

  function position(group, root) {
    let selectedGroup = group
      .selectAll("g:not([class*=nested_child_group])")
      .attr("transform", (d) =>
        d === root ? `translate(0,-30)` : `translate(${x(d.x0)},${y(d.y0)})`
      );

    selectedGroup
      .select("rect")
      .attr("width", (d, i) => (d === root ? width : x(d.x1) - x(d.x0)))
      .attr("height", (d) => (d === root ? 30 : y(d.y1) - y(d.y0)));

    selectedGroup
      .select("text")
      .attr("x", (d) => (x(d.x1) - x(d.x0)) / 2)
      .attr("y", (d) => (y(d.y1) - y(d.y0)) / 2)
      .attr("text-anchor", "middle")
      .text(function (d, i) {
        if (d.depth == 2) {
          let textLength = d.data.name.length * 12 * 0.44;
          let width = x(d.x1) - x(d.x0);

          if (textLength > width - 45) {
            let name = d.data.name;
            let length = Math.floor((width - 50) / (12 * 0.44));
            if (length <= 0) {
              length = 1;
            }
            let newName = name.substr(0, length);
            return newName + "...";
          }
          return d.data.name;
        }
      });
  }

  // When zooming in, draw the new nodes on top, and fade them in.
  function zoomin(d) {
    breadCrumbs
      .append("text")
      .style("fill", "rgb(51, 78, 255)")
      .attr("x", 5)
      .attr("y", -10)
      .style("font-size", "14px")
      .text(
        graphType === "categoriesGraph"
          ? "Accepting Journals"
          : "Rejecting Journals"
      )
      .attr("cursor", "pointer")
      .on("click", () => {
        zoomout(d);
        childTree();
        d3.selectAll(".breadcumb text").remove();
      });

    breadCrumbs
      .append("text")
      .attr("x", 125)
      .attr("y", -10)
      .style("font-size", "14px")
      .style("font-weight", "bold")
      .text(` / ${d.data.name}`);

    d3.select("#legend").attr("opacity", 0);

    const group0 = group.attr("pointer-events", "none");
    const group1 = (group = svg
      .append("g")
      .attr("class", "groups")
      .attr("transform", `translate(0, 20)`)
      .call(render, d));

    x.domain([d.x0, d.x1]);
    y.domain([d.y0, d.y1]);

    svg
      .transition()
      .duration(750)
      .call((t) => group0.transition(t).remove())
      .call((t) =>
        group1
          .transition(t)
          .attrTween("opacity", () => d3.interpolate(0, 1))
          .call(position, d)
      );
  }

  // When zooming out, draw the old nodes on top, and fade them out.
  function zoomout(d) {
    d3.select("#legend").attr("opacity", 1);

    const group0 = group.attr("pointer-events", "none");
    const group1 = (group = svg
      .insert("g", "*")
      .attr("class", "groups")
      .attr("transform", `translate(0, 20)`)
      .call(render, d.parent));

    x.domain([d.parent.x0, d.parent.x1]);
    y.domain([d.parent.y0, d.parent.y1]);

    svg
      .transition()
      .duration(750)
      .call((t) =>
        group0
          .transition(t)
          .remove()
          .attrTween("opacity", () => d3.interpolate(1, 0))
          .call(position, d)
      )
      .call((t) => group1.transition(t).call(position, d.parent));
  }

  return svg.node();
};

function Treemap({ data, graphType }) {
  const dispatch = useDispatch();
  const [svg, setSvg] = useState("");
  const [validContnet, setValidContent] = useState(true);

  useEffect(() => {
    if (Object.keys(data).length) {
      if (data.children && data.children.length >= 1) {
        setValidContent(true);
        setSvg(loadGraph(data, dispatch, graphType));
      } else {
        if (!d3.select("#tree_map > svg").empty()) {
          d3.select("#tree_map > svg").remove();
        }

        setValidContent(false);
        setSvg("");
      }
    }
  }, [data, graphType]);

  return (
    <div className="mt-3 position-relative">
      {svg && <Graphdownload svg={svg} filename={toTitleCase(title)} />}
      {!validContnet && <NoDataFound />}

      <div className="chart-container_tree_map">
        <div id="tree_map"></div>
      </div>
    </div>
  );
}

export default Treemap;
