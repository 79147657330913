import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import { title, toTitleCase } from "../../../Filter/Filter";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import {
  setGraphType,
  setManuscriptModal,
} from "../../../../Redux/Action/filter.action";
import Graphdownload from "../Graphdonwload/Graphdownload";

import "./Sunbrust.scss";
import NoDataFound from "../../../NotFound/NoDataFound";

function loadGraph(data, dispatch) {
  // Specify the chart’s dimensions.
  const width = 928;
  const height = width;
  const radius = width / 10;

  if (!d3.select("#sunburst > svg").empty()) {
    d3.select("#sunburst > svg").remove();
    d3.select("#tooltipSunburst").remove();
    d3.select("#breadcrumb").remove();
  }

  var tooltip = d3.select("#sunburst")
  .append("div")
  .attr("id", "tooltipSunburst")
  .style("position", "absolute")
  .style("display", "none")
  .style("background-color", "white")
  .style("opacity", "1")
  .style("font-size", "12px")
  .style("box-shadow", "1px 1px 5px 1px black")
  .style("text-align", "center")
  .style("padding", "4px")
  .style("margin", "2px")
  .style("border-radius", "5px")

  // Create the color scale.
  const color = d3.scaleOrdinal(
    d3.quantize(
      d3.interpolateCubehelixLong("purple", "orange"),
      data.children.length + 1
    )
  );

  // Compute the layout.
  const hierarchy = d3
    .hierarchy(data)
    .sum((d) => {
      if (d.children) {
        delete d.value;
        return d;
      }

      return d.value;
    })
    .sort((a, b) => b.value - a.value);

  const root = d3.partition().size([2 * Math.PI, hierarchy.height + 1])(
    hierarchy
  );

  root.each((d) => (d.current = d));

  // Create the arc generator.
  const arc = d3
    .arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 1.5)
    .innerRadius((d) => d.y0 * radius)
    .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1))
    .cornerRadius(2);

  // Create the SVG container.
  const svg = d3
    .select("#sunburst")
    .append("svg")
    .attr("viewBox", [-width / 2, -height / 3, width, width - height / 3])
    .style("font", "10px sans-serif");

  const format = d3.format(",d");

  // Append the arcs.
  const path = svg
    .append("g")
    .selectAll("path")
    .data(root.descendants().slice(1))
    .join("path")
    .attr("fill", (d) => {
      while (d.depth > 1) d = d.parent;
      return color(d.data.name);
    })
    .attr("fill-opacity", (d) =>
      arcVisible(d.current) ? (d.children ? 1 : 1) : 0
    )
    .attr("pointer-events", (d) => (arcVisible(d.current) ? "auto" : "none"))
    .attr("d", (d) => arc(d.current));

  path
    .transition()
    .duration(1200)
    .attrTween("d", (d) => {
      var i = d3.interpolate(0, d.y0);
      var j = d3.interpolate(0, d.y1);
      return function (t) {
        d.y0 = i(t);
        d.y1 = j(t);

        return arc(d);
      };
    });

  path
    .on("click", function (e, d) {
      ReactGA.event({
        category: toTitleCase(title),
        action: `Sunburst graph clicked`,
        label: `p_publisher= "${d.parent.data.name}",  p_journal= "${d.data.name}", filterStatus: "totalPubication"`,
      });
      dispatch(
        setGraphType({
          title: `${d.parent.data.name} > ${d.data.name} > TOTAL PUBLICATIONS`,
          graphType: {
            graphType: "publisherTrend",
            p_publisher: d.parent.data.name,
            p_journal: d.data.name,
            filterStatus: "totalPubication",
          },
        })
      );
      dispatch(setManuscriptModal(true));
    })
    .style("cursor", "pointer")
    .on("mouseover", function (event, d) {
      //Tooltip
    tooltip
        .style("display", "block")
        .style("left", event.screenX - 40 + "px")
        .style("top", event.offsetY + 20 + "px")
        .html(`<b>${d.data.name}: </b><span>${format(d.value)}</span>`);
    })
    .on("mouseout", function (event, d) {
      //Tooltip removed
      tooltip.style("display", "none")

    })
    .on("mousemove", function (event, d) {
      // Tooltip position
      tooltip
        .style("left", event.screenX - 40+ "px")
        .style("top", event.offsetY + 20 + "px");
    });

  // Make them clickable if they have children.
  path
    .filter((d) => d.children)
    .style("cursor", "pointer")
    .on("click", clicked);

  const label = svg
    .append("g")
    .attr("pointer-events", "none")
    .attr("text-anchor", "middle")
    .style("user-select", "none")
    .selectAll("text")
    .data(root.descendants().slice(1))
    .join("text")
    .attr("dy", "0.35em")
    .attr("fill-opacity", (d) => +labelVisible(d.current))
    .attr("transform", (d) => labelTransform(d.current))
    .text(
      (d) => d.data.name.substr(0, 15) + (d.data.name.length > 15 ? "..." : "")
    )
    .attr("fill", "#fff");

  function textFormat(text) {
    return text.substr(0, 26) + (text.length > 26 ? "..." : "");
  }

  const parent = svg
    .append("circle")
    .datum(root)
    // .attr("r", radius-1)
    .attr("r", 0)
    .attr("fill", color(data.name))
    .attr("pointer-events", "all");

  parent
    .transition()
    .duration(1200)
    .attr("r", radius - 1);

  parent.on("click", clicked);
  // .on("mouseover", function(event, d){
  //     const format = d3.format(",d");
  //     //Tooltip
  //     d3.select("#sunburst")
  //     .append("div")
  //     .attr("id", "tooltip")
  //     .style("position", "absolute")
  //     .style("background-color", "white")
  //     .style("opacity", "1")
  //     .style("font-size", "12px")
  //     .style("box-shadow", "1px 1px 5px 1px black")
  //     .style("text-align", "center")
  //     .style("padding", "4px")
  //     .style("margin", "2px")
  //     .style('border-radius', '5px')
  //     .style("left", event.screenX - 50 + "px")
  //     .style("top", event.offsetY + 20 + "px")
  //     .html(() =>{
  //         var val = ""
  //         if(d.data.value) {
  //             val =  format(d.data.value);

  //         }

  //         console.log(data)

  //        return `<b>${d.data.name}: </b><span>${val}</span>`;
  //     });
  // })
  // .on("mouseout", function(event, d){
  //     //Tooltip removed
  //     d3.select("#tooltip").remove();
  // })
  // .on("mousemove", function (event, d) {
  //     // Tooltip position
  //     d3.select("#tooltip")
  //         .style("left", event.screenX - 50 + "px")
  //         .style("top", event.offsetY + 20 + "px");
  // });

  svg
    .append("text")
    .attr("id", "title")
    .style("position", "absolute")
    .style("font-size", "0.75rem")
    .attr("text-anchor", "middle")
    .attr("fill", "#fff")
    .text(textFormat(data.name))
    .attr("opacity", 1)
    .append("title")
    .text(data.name)
  
   

  function setGraph(p, title) {
    d3.selectAll("circle")
      .attr("fill", color(p.data.name))
      .transition()
      .duration(750)
      .attr("r", radius - 1 + (p.depth / 2) * radius);

    d3.select("#title").text(textFormat(p.data.name))    
    .append("title")
    .text(p.data.name)

    if (title) {
      d3.select("#breadcrumb").append("text").html(` / <b>${title}</b>`);
    }

    parent.datum(p.parent || root);

    root.each(
      (d) =>
        (d.target = {
          x0:
            Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          x1:
            Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          // y0: Math.max(0, d.y0 - p.depth),
          y0: Math.max(0, d.y0 - p.depth / 2),
          y1: Math.max(0, d.y1),
          // y1: Math.max(0, d.y1 - p.depth)
        })
    );

    const t = svg.transition().duration(750);

    // Transition the data on all arcs, even the ones that aren’t visible,
    // so that if this transition is interrupted, entering arcs will start
    // the next transition from the desired position.
    path
      .transition(t)
      .tween("data", (d) => {
        const i = d3.interpolate(d.current, d.target);
        return (t) => (d.current = i(t));
      })
      .filter(function (d) {
        return +this.getAttribute("fill-opacity") || arcVisible(d.target);
      })
      .attr("fill-opacity", (d) =>
        arcVisible(d.target) ? (d.children ? 1 : 1) : 0
      )
      .attr("pointer-events", (d) => (arcVisible(d.target) ? "auto" : "none"))

      .attrTween("d", (d) => () => arc(d.current));

    label
      .filter(function (d) {
        return +this.getAttribute("fill-opacity") || labelVisible(d.target);
      })
      .transition(t)
      .attr("fill-opacity", (d) => +labelVisible(d.target))
      .attrTween("transform", (d) => () => labelTransform(d.current));
  }

  // Handle zoom on click.
  function clicked(event, p) {
    const formattedData = p
      .ancestors()
      .map((p) => p.data.name)
      .reverse();

    if (p.parent) {
      d3.select("#sunburst")
        .append("div")
        .attr("id", "breadcrumb")
        .style("position", "absolute")
        .style("font-size", "14px")
        .style("margin", "1rem")
        .style("left", 0 + "px")
        .style("top", 0 + "px")
        .append("text")
        .style("color", "rgb(51, 78, 255)")
        .style("cursor", "pointer")
        .html(formattedData[0])
        .on("click", function () {
          setGraph(root, formattedData[1]);
          d3.select("#breadcrumb").remove();
        });
    } else {
      d3.select("#breadcrumb").remove();
    }

    setGraph(p, formattedData[1]);
  }

  function arcVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
  }

  function labelVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.09;
  }

  function labelTransform(d) {
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y = ((d.y0 + d.y1) / 2) * radius;
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
  }

  return svg.node();
}

function Sunburst({ data }) {
  const dispatch = useDispatch();
  const [svg, setSvg] = useState("");
  const [validContnet, setValidContent] = useState(true);

  useEffect(() => {
    if (Object.keys(data).length && data.children && data.children.length >= 1) {
        setValidContent(true);
        setSvg(loadGraph(data, dispatch));
      } else {
        setValidContent(false);
      }
    
  }, [data]);

  return (
    <div className="mt-3 position-relative">
      {!validContnet && <NoDataFound />}

      {svg && <Graphdownload svg={svg} filename={toTitleCase(title)} />}
      <div className="chart-container_sunburst">
        <div id="sunburst"></div>
      </div>
    </div>
  );
}

export default Sunburst;
