import React, { useState } from 'react'
import CardItem from './CardItem'

const CardDetails = ({getAllRecords, loading, setSort, status, setStatus}) => {
  const  cards = [
    {
      status: "rejected",
      title: "Rejected Manuscripts"
    },
    {
      status: "published",
      title: "Published Manuscripts"
    },
    {
      status: "",
      title: "Unpublished"
    },
  ]

  const [active, setActive] = useState('rejected')

  return (
    <div className='row row-cols-1 row-cols-md-3 mt-3'>
      {cards.map((item, index)=> (
      <CardItem item={item} title={item} 
      key={index} active={active} setActive={setActive}
      getAllRecords={getAllRecords}
      loading={loading} setSort={setSort}
      status={status}
      setStatus={setStatus}
      />
      ))}
      
    </div>
  )
}

export default CardDetails
