import React from "react";
import classNames from 'classnames';
import styles from './Layout.module.scss';
import { FaLinkedinIn, FaYoutube } from "react-icons/fa"

function Footer(){

    const currentPage = window.location.pathname.split("/");

    return(
        <footer className={styles.root}>
            {currentPage.splice(-1)[0] !== 'resetPassword' ? 
            <>
          
            <div>
                <div className={classNames(styles.footer2, "d-flex container-fluid align-items-center flex-row")}>
                    <div className="col-lg-6 text-start">
                        Copyright Ⓒ {new Date().getFullYear()} Molecular Connections Pvt. Ltd. | All Rights Reserved
                    </div>
                    <div className="col-lg-3 text-start">
                        <a href="https://in.linkedin.com/company/molecular-connections" rel="noreferrer" target="_blank" className="text-reset"><FaLinkedinIn className="mx-3" size="15px" /></a>
                        <a href="https://www.youtube.com/channel/UCesUFzA8D8us3A__S73MrRQ" rel="noreferrer" target="_blank" className="text-reset"><FaYoutube className="" size="15px" /></a>
                    </div>
                    <div className="col-lg-3 text-end">
                        <a href="https://molecularconnections.com/privacy-policy/" rel="noreferrer" target="_blank" className="text-reset">Privacy Policy</a>
                    </div>
                </div>
            </div></> : null}
        </footer>
    )
}

export default Footer;