import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function NoDataFound() {
  return (
    <div className="notFound text-center">
      <div>
        <h6>
          <FontAwesomeIcon icon={faInfoCircle} className="text-info me-1" />
          No data found
        </h6>
        <p className="text-muted">Try adjusting your filter.</p>
      </div>
    </div>
  );
}

export default NoDataFound;
