import React, { useState, useEffect } from "react";
import { title, toTitleCase } from "../../../Filter/Filter";
import ReactGA from "react-ga4";
import * as d3 from "d3";
import { useDispatch } from "react-redux";
import {
  setManuscriptModal,
  setGraphType,
} from "../../../../Redux/Action/filter.action";
import Graphdownload from "../Graphdonwload/Graphdownload";
import TooltipButton from "../../../TooltipButton/TooltipButton";
import { journalCascadeMessage } from "../../../TooltipButton/TooltipMessage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// import "./MultiLine.scss";
import "./MultiBarCustomChart.scss";
import NoDataFound from "../../../NotFound/NoDataFound";

function loadGraph(data, dispatch, journalType) {
  const legentWidth = 150;
  const margin = { top: 60, right: 30, bottom: 80, left: 60 };
  const width = 1100 - margin.left - margin.right + legentWidth;
  const height = 600 - margin.top - margin.bottom;

  let duration = 250;
  const barIndex = d3.local();

  function percentageCalculator(item_1, item_2) {
    const totalSum = item_1 + item_2;

    if (!totalSum) {
      return 0;
    }

    return Math.round(Math.abs((item_1 * 100) / totalSum));
  }

  if (!d3.select("#multi_bar_custom_chart > svg").empty()) {
    d3.select("#multi_bar_custom_chart > svg").remove();
  }

  const svg = d3

    .select("#multi_bar_custom_chart")
    .append("svg")
    .attr("viewBox", [
      0,
      0,
      width + margin.left + margin.right,
      height + margin.top + margin.bottom,
    ])
    // .attr("width", width + margin.left + margin.right)
    // .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left + 50},${margin.top})`);

  const journals = data.series.map((d) => d.name);
  const years = data.years;
  const subgroups = ["with_us", "elsewhere"];
  const modelText = { with_us: "With us", elsewhere: "Elsewere" };

  // Format the data for D3's stack
  const formattedData = data.series.map((journal, index) => {
    return {
      name: journal.name,
      data: journal.with_us.map((_, i) => ({
        year: years[i],
        with_us: journal.with_us[i],
        elsewhere: journal.elsewhere[i],
      })),
      // year: years[index],
    };
  });

  // X axis (journals)
  const x0 = d3
    .scaleBand()
    .domain(journals)
    .range([0, width - legentWidth])
    .padding(0.2);

  // svg
  //   .append("g")
  //   .attr("transform", `translate(0,${height})`)
  //   .call(d3.axisBottom(x0))
  //   .selectAll("text")
  //   .style("text-anchor", "middle")
  //   .call(wrapText, x0.bandwidth());

  // X1 axis (years within each journal)
  const x1 = d3
    .scaleBand()
    .domain(years)
    .range([0, x0.bandwidth()])
    .padding(0.05);

  const yearsDomain = [...years, ""]; //+years.at(-1) + +years.at(-1) - +years.at(-2)

  const year_axis = d3
    .scaleBand()
    .domain(yearsDomain)
    .range([height, 0])
    .paddingInner(1)
    // .padding(1)
    // .paddingOuter(1)
    .align(1);

  // Y axis
  const y = d3
    .scaleLinear()
    // .domain([
    //   0,
    //   d3.max(
    //     data.series.flatMap((journal) =>
    //       journal.with_us.concat(journal.elsewhere)
    //     )
    //   ),
    // ])
    .domain([0, 100])
    .nice()
    .range([height, height - year_axis.step() + 10]);

  function xAxis(year, index) {
    svg
      .append("g")
      // .attr("transform", `translate(0,${height})`)
      .attr("class", "loop x axis")
      .attr("transform", `translate(0,${year_axis(year)})`)
      .call(d3.axisBottom(x0))
      .selectAll("text")
      // .remove();
      .attr("display", () => {
        if (index) return "none";
      })
      .style("text-anchor", "middle")
      .call(wrapText, x0.bandwidth());
  }

  function yAxis(year) {
    svg
      .append("g")
      .attr("transform", `translate(0,${-(height - year_axis(year))})`)
      .call(
        d3
          .axisLeft(y)
          .ticks(5, ",f")
          .tickFormat((d) => `${d}%`)
      );
  }

  svg
    .append("g")
    .attr("transform", `translate(-50,${0})`)
    .call(d3.axisLeft(year_axis));

  // Color scale
  const color = d3.scaleOrdinal().domain(subgroups).range([
    "#544fc5", // original deep purple-blue
    "#4CAF50", // original green
    "#2caffe", // original bright cyan
    "#7e72d1", // light lavender
    "#1d3fb5", // dark royal blue
    "#35bfff", // light sky blue
    "#349a3e", // forest green
    "#f4e04d", // bright yellow
    "#ff7e29", // vibrant orange
    "#ff4c4c", // vivid red
    "#d1e3ff", // very light blue
    "#9881d0", // light purple
    "#71d45e", // lime green
  ]);

  // function wrapText(text, width) {
  //   text.each(function () {
  //     const text = d3.select(this);
  //     const words = text.text().split(/\s+/).reverse();
  //     let word;
  //     let line = [];
  //     let lineNumber = 0;
  //     const lineHeight = 1.1; // ems
  //     const y = text.attr("y");
  //     const dy = parseFloat(text.attr("dy"));
  //     let tspan = text
  //       .text(null)
  //       .append("tspan")
  //       .attr("x", 0)
  //       .attr("y", y)
  //       .attr("dy", dy + "em");

  //     while ((word = words.pop())) {
  //       line.push(word);
  //       tspan.text(line.join(" "));
  //       if (tspan.node().getComputedTextLength() > width) {
  //         line.pop();
  //         tspan.text(line.join(" "));
  //         line = [word];
  //         tspan = text
  //           .append("tspan")
  //           .attr("x", 0)
  //           .attr("y", y)
  //           .attr("dy", ++lineNumber * lineHeight + dy + "em")
  //           .text(word);
  //       }
  //     }
  //   });
  // }

  function wrapText(text, width) {
    text.each(function () {
      const text = d3.select(this);
      const fullText = text.text(); // Store the full text
      const words = fullText.split(/\s+/).reverse();
      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.1; // ems
      const maxLines = 4; // Maximum number of lines
      const y = text.attr("y");
      const dy = parseFloat(text.attr("dy"));
      let tspan = text
        .text(null)
        .append("tspan")
        .attr("x", 0)
        .attr("y", y)
        .attr("dy", dy + "em");

      let isClipped = false; // Flag to check if text is clipped

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));

        if (tspan.node().getComputedTextLength() > width) {
          line.pop(); // Remove the last word that caused overflow
          tspan.text(line.join(" "));

          if (lineNumber + 1 >= maxLines) {
            // If it's the last allowed line, add "..." and break the loop
            tspan.text(line.join(" ") + "...");
            isClipped = true; // Mark text as clipped
            break;
          }

          // Start a new line
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }

      // Add a <title> element if the text was clipped
      // if (isClipped) {
      text.append("title").text(fullText); // Add a <title> element with the full text
      // }
    });
  }

  years.forEach(function (year, index) {
    const barWidthFraction = 3;
    yAxis(year);
    xAxis(year, index);

    const journalGroup = svg
      .append("g")
      .attr("class", `"test class - " ${index}`)
      .attr("transform", `translate(0, ${year_axis(year) - height})`);

    data?.series?.forEach(function (d, j_index) {
      journalGroup
        .append("rect")
        .attr("x", x0(d.name) + x0.bandwidth() / (barWidthFraction * 2))
        .attr(
          "y",
          y(percentageCalculator(d.with_us[index], d.elsewhere[index]))
        )

        .attr("width", x0.bandwidth() / barWidthFraction)
        .attr(
          "height",

          height - y(percentageCalculator(d.with_us[index], d.elsewhere[index]))
        )

        .attr("fill", color("with_us"))
        .each(function (_d, _i) {
          barIndex.set(this, index);
        })
        .on("mouseover", function (event, dx) {
          mouseoverFunction(d, index, "with_us", this);
        })
        .on("mouseout", function () {
          mouseoutFunction(this);
        })
        .on("click", function (event) {
          clickFunction(d, "with_us", this);
        });

      journalGroup
        .append("rect")
        .attr("x", x0(d.name) + x0.bandwidth() / 2)
        .attr(
          "y",
          y(percentageCalculator(d.elsewhere[index], d.with_us[index]))
        )
        // .attr("y", y(d.elsewhere))
        .attr("width", x0.bandwidth() / barWidthFraction)
        .attr(
          "height",
          height - y(percentageCalculator(d.elsewhere[index], d.with_us[index]))
        )
        // .attr("height", height - y(d.elsewhere))
        .attr("fill", color("elsewhere"))
        .each(function (_d, _i) {
          barIndex.set(this, index);
        })
        .on("mouseover", function (event, dx) {
          mouseoverFunction(d, index, "elsewhere", this);
        })
        .on("mouseout", function () {
          mouseoutFunction(this);
        })
        .on("click", function (event) {
          clickFunction(d, "elsewhere", this);
        });
    });
  });

  //CLICK FUNCTION
  function clickFunction(d, acceptedBy, thisValue) {
    if (thisValue) {
      ReactGA.event({
        category: toTitleCase(title),
        action: `Stack Bar Graph clicked`,
        label: `s_journal= ${d.name}, filterStatus= ${
          acceptedBy == "with_us" ? "Internal" : "External"
        }`,
      });
      dispatch(
        setGraphType({
          title: `${d.name} > ${years[barIndex.get(thisValue)]}`,
          graphType: {
            graphType: journalType,
            s_journal: d.name,
            filterStatus: acceptedBy == "with_us" ? "Internal" : "External",
            s_rejection_date: years[barIndex.get(thisValue)],
          },
        })
      );
      dispatch(setManuscriptModal(true));
    }
  }

  // MOUSEOUT FUNCTION
  function mouseoutFunction() {
    d3.select(this)
      .style("cursor", "none")
      .transition()
      .duration(duration)
      .selectAll(".text")
      .remove();

    d3.select("#tooltip").remove();

    d3.select("g#tooltipGroup").remove();
  }

  // MOUSEOVER FUNCTION
  function mouseoverFunction(d, year_index, acceptedBy, thisValue) {
    d3.select(this).style("cursor", "pointer");

    // let height = y(d[1]);
    let tooltipWidth = 150;
    let tooltipHeight = 35;

    const tooltipGroup = d3
      .select("#multi_bar_custom_chart svg")
      .append("g")
      .attr("id", "tooltipGroup")
      // .style("text-anchor", "end")
      .attr(
        "transform",
        `translate(${x0(d.name) + margin.left + 2}, 0)` //${y(d[1]) + 0}
      );

    tooltipGroup
      .append("rect")
      .attr("width", tooltipWidth)
      .attr("height", tooltipHeight)
      .attr("fill", "white")
      .attr("id", "rectBackground")
      // .attr("x", spaceForLabels + x(d))
      .attr("y", -tooltipHeight / 2 + 4)
      .attr("rx", "5")
      .style("box-shadow", "1px 1px 5px 1px black")
      .style("filter", "drop-shadow(0px 0px 3px rgba(0, 0, 0, 1)");

    let upperText = tooltipGroup
      .append("text")
      .attr("id", "tooltip_upper_text")
      .attr("x", 7);

    upperText
      .append("tspan")
      .attr("font-size", 10)
      .attr("font-weight", "bold")
      .attr("fill", color(acceptedBy))
      .text(years[barIndex.get(thisValue)]);

    let lowerText = tooltipGroup
      .append("text")
      .attr("x", 7)
      .attr("y", +15)
      .attr("id", "tooltip_lower_text");

    lowerText.append("tspan").attr("font-size", 18).attr("font-weight", "bold");

    lowerText.append("tspan").attr("font-size", 12).text(d.name);

    lowerText
      .append("tspan")
      .attr("font-size", 12)
      .attr("font-weight", "bold")
      .text(
        `: ${percentageCalculator(
          d[acceptedBy][barIndex.get(thisValue)],
          d.data[barIndex.get(thisValue)] -
            d[acceptedBy][barIndex.get(thisValue)]
        )}%`
      );

    lowerText
      .append("tspan")
      .attr("font-size", 12)
      .text(
        ` ( ${d[acceptedBy][barIndex.get(thisValue)]} ${
          modelText[acceptedBy]
        } )`
      );

    let upperTextElement = document.getElementById("tooltip_upper_text");
    let lowerTextElement = document.getElementById("tooltip_lower_text");
    let upperTextBoundingBox = upperTextElement.getBBox();
    let lowerTextBoundingBox = lowerTextElement.getBBox();

    // Get the dimensions of the bounding box
    let upperTextWidth = upperTextBoundingBox.width;

    let lowerTextWidth = lowerTextBoundingBox.width;

    let textWidth =
      upperTextWidth > lowerTextWidth + 10
        ? +upperTextWidth
        : +lowerTextWidth + 10;

    // Set the width and height of the background rectangle
    let rectBackground = document.getElementById("rectBackground");
    let tooltipGroupBackground = document.getElementById("tooltipGroup");
    rectBackground.setAttribute("width", textWidth + 0); // Add padding for better visibility

    let shiftRight = 0;
    //   x( data.series[barIndex.get(this)].name) +
    //   margin.left
    // - rectBackground.getAttribute("width") / 2;
    // if (shiftRight < 0) {
    //   shiftRight = -shiftRight + 10;
    // }

    // let position_x =
    //   document.getElementById("multi_line").offsetWidth -
    //     (+x0(data.series[barIndex.get(thisValue)].name) + margin.left) >
    //   // - rectBackground.getAttribute("width") / 2
    //   textWidth
    //     ? +x0(data.series[barIndex.get(thisValue)].name) + margin.left >
    //       // - rectBackground.getAttribute("width") / 2
    //       0
    //       ? +x0(data.series[barIndex.get(thisValue)].name) + margin.left
    //       : // - rectBackground.getAttribute("width") / 2
    //         +x0(data.series[barIndex.get(thisValue)].name) +
    //         margin.left +
    //         // - rectBackground.getAttribute("width") / 2
    //         shiftRight
    //     : +x0(data.series[barIndex.get(thisValue)].name) +
    //       margin.left -
    //       rectBackground.getAttribute("width") / 2;

    let position_x =
      document.getElementById("multi_bar_custom_chart").offsetWidth -
        (+x0(d.name) + margin.left) >
      // - rectBackground.getAttribute("width") / 2
      textWidth
        ? +x0(d.name) + margin.left >
          // - rectBackground.getAttribute("width") / 2
          0
          ? +x0(d.name) + margin.left
          : // - rectBackground.getAttribute("width") / 2
            +x0(d.name) +
            margin.left +
            // - rectBackground.getAttribute("width") / 2
            shiftRight
        : +x0(d.name) + margin.left - rectBackground.getAttribute("width") / 2;

    // let position_y =
    //   height > rectBackground.getAttribute("height") / 2
    //     ? height
    //     : rectBackground.getAttribute("height") / 2;

    let position_y =
      year_axis(years[barIndex.get(thisValue)]) -
      (height -
        y(
          percentageCalculator(
            d[acceptedBy][barIndex.get(thisValue)],
            d.data[barIndex.get(thisValue)] -
              d[acceptedBy][barIndex.get(thisValue)]
          )
        )) +
      20;

    tooltipGroupBackground.setAttribute(
      "transform",
      `translate(${position_x},${position_y})`
    );
  }

  // Add labels for legend
  const legend = svg
    .selectAll(".legend")
    .data(subgroups)
    .enter()
    .append("g")
    .attr("class", "legend")
    .attr("transform", (d, i) => `translate(0,${i * 20})`);

  legend
    .append("rect")
    .attr("x", width - legentWidth)
    .attr("width", 18)
    .attr("height", 18)
    .style("fill", color);

  legend
    .append("text")
    .attr("x", width - legentWidth + 20)
    .attr("y", 9)
    .attr("dy", ".35em")
    .style("text-anchor", "start")
    .text((d) => modelText[d]);

  //X-axis text
  svg
    .append("text")
    .attr("class", "x label")
    // .attr("text-anchor", "end")
    .attr("x", (width - margin.left - margin.right - legentWidth) / 2)
    .attr("y", height + margin.top)
    .attr("dy", ".75em")
    // .attr("transform", "rotate(-90)")
    .attr("font-size", "14px")
    .text("Rejecting Journal");

  //Y-axis text
  svg
    .append("text")
    .attr("class", "y label")
    .attr("text-anchor", "end")
    .attr("x", -(height - margin.top - margin.bottom) / 2)
    .attr("y", -margin.left - margin.right - 10)
    .attr("dy", ".75em")
    .attr("transform", "rotate(-90)")
    .attr("font-size", "14px")
    .text("Publication Count");

  return d3.select("#multi_bar_custom_chart > svg").node();
}

function MultiBarCustomChart({ data, journalType }) {
  const [validContnet, setValidContent] = useState(true);
  const [svg, setSvg] = useState("");
  const dispatch = useDispatch();

  const graphName = (type) => {
    switch (type) {
      case "journalCascade":
        return "Journal Cascade";
      default:
        return " ";
    }
  };

  const JournalsMessage = (type) => {
    switch (type) {
      case "journalCascade":
        return journalCascadeMessage;

      default:
        return " ";
    }
  };

  useEffect(() => {
    if (Object.keys(data).length && data.series && data.series.length >= 1) {
      setValidContent(true);
      setSvg(loadGraph(data, dispatch, journalType));
    } else {
      setValidContent(false);
    }
  }, [data]);

  return (
    <div className="my-3 card card-body">
      <h5>
        {/* {journalType === "rejectingJournal" ? "Rejecting" : "Accepting "}{" "}
        Journals and Respective Citations */}
        {graphName(journalType)}
        <TooltipButton
          tooltipMessage={
            JournalsMessage(journalType)
            // journalType === "rejectingJournal"
            //   ? rejectingJournalsMessage
            //   : acceptingJournalsMessage
          }
          placement="right"
          type={"string"}
          classes={"TooltipDiv"}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="sm"
            className="fs-14 ps-2 text-secondary"
          />
        </TooltipButton>
      </h5>
      <div className="mt-3  mainChartMultiLine position-relative">
        {!validContnet && <NoDataFound />}

        {svg && <Graphdownload svg={svg} filename={toTitleCase(title)} />}
        <div className="chart-container">
          <div id="multi_bar_custom_chart"></div>
        </div>
      </div>
    </div>
  );
}

export default MultiBarCustomChart;
