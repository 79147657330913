import React from "react";
import { Table } from "react-bootstrap";
import "./TableDetails.scss";
import TableHeadItem from "./TableHeadItem";
import TableItem from "./TableIDatatem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const TableDetails = ({ tableData, headers, start, handleSort, sort }) => {
  return (
    <Table responsive className="tableBlock table-bordered table-hover mb-0">
      <thead className="text-uppercase">
        <tr className="align-middle" key={"No"}>
          <th width="7%">No.</th>
          {headers?.sort((a,b)=> a?.tableRank - b?.tableRank)?.map((h, idx) => (
            <TableHeadItem
              headingData={h}
              dataKey={idx}
              handleChange={handleSort}
              sort={sort}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.length > 0 ? (
          tableData?.map((data, idx) => (
            <tr className="align-middle" key={idx}>
              <td className="py-3" key={idx}>
                <span className="idxStyle">{start + idx + 1}</span>
              </td>
              {headers?.map((h, idx) => (
                h.tableDisplay && <TableItem rowData={data} headerDetails={h} headers={headers} dataKey={idx} />
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={7}>
              <div className="text-center">
                <h6>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="text-info me-1"
                  />
                  No data found
                </h6>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default TableDetails;
