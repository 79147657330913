import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Provider } from 'react-redux';
import { store } from './Redux/store';
import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_ID } from "./utils/Constant";

ReactGA.initialize(GOOGLE_ANALYTICS_ID);

// ReactGA.send({ 
// hitType: "pageview", 
// page: window.location.pathname,
// // title: "Custom Title" 
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
