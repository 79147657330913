import axios from "axios";
import {store} from "../../Redux/store";
import { API_BASE_URL } from "../Constant";
import { openLoginModal } from "../../Redux/Action/login.action";
import { tokenExpired } from "../../Redux/Action/token.action";
import LocalStorageService from "./AuthServiceStorage";

/**
 *
 * @version
 * @author: sandeep_k
 * @create date: 2020-02-05
 * @modified by: arjun.s
 * @modified date:  2020-03-04
 *
 * Auth Services - login , refresh access token, data request
 * Modified : Token refresh fixes by arjun.s
 */

/* Abstraction of the methods in Auth Service Constants */
const localService = LocalStorageService.getService();

/* -- Start of Axios Interceptors for Data Apis -- */
const axiosInstance = axios.create({
  baseURL: API_BASE_URL
});

axiosInstance.interceptors.request.use(request => requestHandler(request));

/* Setting the headers for axios request */
const requestHandler = request => {
  request.headers["token"] = localService.getAccessToken();
  return request;
};
/* -- End of Axios Interceptors for Data Apis -- */

/* -- Start of Axios Interceptors for Authentication Apis -- */
const authAxiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

/* -- End of Axios Interceptors for Authentication Apis -- */

/* -- Start of Api requests -- */
var refreshing = false;
let refreshSubscribers = [];

axiosInstance.interceptors.response.use(null, async error => {
  if (error.config && error.response && error.response.status === 400) {
    if(error.response.data instanceof Blob) {
      const blob = new Blob([error.response.data]);
      const data = JSON.parse(await blob.text());
      if(data.errorCode === 400){
        if (!refreshing) {
          refreshing = true;
            onRefreshToken().then(newToken => {
              refreshing = false;
              onRrefreshed(newToken);
              refreshSubscribers = [];
            }).catch(error => {
              refreshing = false
            });
          }

        return new Promise((resolve, reject) => {
          subscribeTokenRefresh(token => {
            // replace the expired token and retry
            resolve(axiosInstance.request(error.config));
          });
        });

      }else{
        return Promise.reject(error);
      }
    }else{
      if(error.response.data.errorCode === 400){
        if (!refreshing) {
          refreshing = true;
            onRefreshToken().then(newToken => {
              refreshing = false;
              onRrefreshed(newToken);
              refreshSubscribers = [];
            }).catch(error => {
              refreshing = false
            });
        }
        return new Promise((resolve, reject) => {
          subscribeTokenRefresh(token => {
            // replace the expired token and retry
            resolve(axiosInstance.request(error.config));
          });
        });
      }
      else{
        return Promise.reject(error);
      }
    }
   
    
  }

  if (error.config && error.response && error.response.status === 401) {
   // store.dispatch(openModal());
    return Promise.reject(error);
  }

  return Promise.reject(error);
});

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
  refreshSubscribers.map(cb => cb(token));
}

/* Api request to fetch the data */
export const dataDisplayRequest = async config => {
  return await axiosInstance.request(config).then(function(response) {
    return Promise.resolve(response);
  });
};

/* Api request to fetch refresh token */
export const onRefreshToken = () => {
  const params = {"accessToken": localService.getAccessToken(), "refreshToken": localService.getRefreshToken(), "grantType": "refresh_token"}

  const refreshConfig = {
    url: "api/v1/oauth/getAccessToken",
    method: "post",
    data: params
  };

  return authAxiosInstance
    .request(refreshConfig)
    .then(async function(response) {
      localService.setToken(response.data);
      return Promise.resolve(response);
    })
    .catch(function(error) {
      switch (error.response.status) {
        case 401:
          store.dispatch(openLoginModal());
          store.dispatch(tokenExpired());
          return Promise.reject(error);
        default:
          return Promise.reject(error);
      }
    });
};

/* Api request for login */
export const authLogin = config => {
  return authAxiosInstance
    .request(config)
    .then(function(response) {
      const data = response.data;
      localService.setToken(data);
      localService.setAuthDetails(JSON.stringify(data));
      //store.dispatch(closeModal());
      return Promise.resolve(response.data);
    })
    .catch(async function(error) {
      return Promise.reject(error);
    });
};


/* Api request for Logout */
export const authLogout = config => {
  return authAxiosInstance
    .request(config)
    .then(function(response) {
      return Promise.resolve(response.data);
    })
    .catch(async function(error) {
      return Promise.reject(error);
    });
};