import { ActionType } from "./Types"

export function tokenExpired(){
    return{
        type: ActionType.TOKEN_EXPIRED,
        payload: false
    }
}

export function tokenRefreshed(){
    return{
        type: ActionType.TOKEN_REFRESHED,
        payload: true
    }
}