import React, {useState, useEffect} from 'react';
import Filter, { handleGAEvent } from '../../components/Filter/Filter';
import { useSelector, useDispatch } from 'react-redux';
import { setTableLoader } from '../../Redux/Action/filter.action';
import AxiosService from '../../utils/AxiosService';
import { setJournalGridData } from '../../Redux/Action/journal.action';
import { SpinnerLoader } from '../../components/Loader/Loader';
import TableGridLayout from '../../components/TableGridLayout/TableGridLayout';
import GroupedHorizontalBar from '../../components/graphs/D3/GroupedHorizontalBar/GroupedHorizontalBar';

const InsightsByAcceptingJournals = () => {
    const [loading, setLoading] = useState({isApplyFilter: false, isClearFilter: false, isGo:false})
    const {
        dateRange, searchValue, currentPage,
        acceptingJournal, rejectingJournal, publishers, accessType,
        status, isPageReload
    } = useSelector((state)=> state.allFilters)
    
    const dispatch = useDispatch();
    const [start, setStart] = useState(0);
    const offset = 10;

    const [sort, setSort] = useState({
        field: "totalPublication",
        order: "desc"
    })

    const [chartLoader, setChartLoader]= useState(false);
    const axiosService = new AxiosService();
    const [chartData, setChartData] = useState({});
    const[reqBody, setReqBody] = useState({})

    const {journalGrid} = useSelector((state)=> state.journal)
    
    const getAllRecords= async()=>{
        let startDate=""
        let endDate=""
    
        if(dateRange.rejectionStartDate.length > 10 || dateRange.rejectionEndDate.length > 10) {
        startDate= dateRange.rejectionStartDate.slice(0,10)
        endDate= dateRange.rejectionEndDate.slice(0,10)
        }else {
        startDate= dateRange.rejectionStartDate
        endDate= dateRange.rejectionEndDate
        }
        dispatch(setTableLoader(true))
        const start = (currentPage - 1) * offset;
        setStart(start)
        const reqBody = {
          "vendor": "",
          "start_date": startDate,
          "end_date": endDate,
          "status": status,
          "searchTerm": searchValue,
          "filter": {
            "s_journal": rejectingJournal,
            "p_journal": acceptingJournal,
            "p_publisher": publishers,
          },
          "sort": {
            "field": sort.field,
            "order": sort.order
          }
        }
    
        if(accessType){
          reqBody["filter"].oa= true
        }
    
        setChartLoader(true)
        axiosService.getAcceptJournalGridDetails(start, offset, reqBody).then(response => {
        if(response.data) {
            dispatch(setTableLoader(false))
            dispatch(setJournalGridData(response.data))
            setLoading({...loading, isApplyFilter:false, isClearFilter: false, isGo:false})
        }
        }).catch(error => {
        dispatch(setTableLoader(false))
        dispatch(setJournalGridData({}))
        })

        axiosService.getJournalChartDetails(start, offset, reqBody).then(res=> {
        setChartData(res?.data?.accepting)
        setChartLoader(false)
        }).catch(error=> {
        console.log(error)
        setChartLoader(false)
        setChartData({})
        }) 
        setReqBody(reqBody)
        handleGAEvent(loading, reqBody)
    }

    useEffect(()=> {
        if(dateRange.rejectionStartDate) {
          getAllRecords()
        }
    }, [currentPage, sort, isPageReload])

    useEffect(()=> {
        if((loading.isApplyFilter || loading.isClearFilter || loading.isGo) && dateRange.rejectionStartDate){
          setSort({
            field: "totalPublication",
            order: "desc"
          })
        }
    },[loading.isApplyFilter, loading.isClearFilter, loading.isGo])

    useEffect(()=>{
        window.scrollTo({top: 0, behavior: 'smooth'})
    },[dateRange])

    return(
        <div>
            <Filter loading={loading}
            setLoading={setLoading}
            getAllRecords={getAllRecords}
            />
            {chartLoader ?
            <div className='loaderSection mt-4 card card-body'>
                <SpinnerLoader size='lg' />
            </div>
            : 
              <GroupedHorizontalBar data={chartData} journalType={"acceptingJournal"} /> 
            }
            <TableGridLayout data={journalGrid} 
            start={start} setStart={setStart} 
            offset={offset} 
            gridTitle={"Journals"}
            getAllRecords={getAllRecords}
            sort={sort}
            setSort={setSort}
            reqBody={reqBody}
            gridType="acceptJournalTrendGrid"
            />
        </div>
    )

}

export default InsightsByAcceptingJournals;