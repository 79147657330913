import React, { useEffect, useState } from 'react'
import { Card, Col,  Row } from 'react-bootstrap'
import AxiosService from '../../utils/AxiosService'
import  "./CardDetails.scss"
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import { SpinnerLoader } from '../Loader/Loader'
import NoDataFound from '../NotFound/NoDataFound'
import Line from '../graphs/D3/Line/Line'

const CardItem = ({item, active, setActive, getAllRecords, loading, setSort, status, setStatus}) => {
    const axiosService = new AxiosService()
    const [data, setData] = useState({})
    const {searchValue, dateRange, acceptingJournal, publishers, rejectingJournal, accessType, isPageReload} = useSelector((state)=> state.allFilters)
    const [cardLoading, setCardLoading]= useState(false)
   
    const fetchCardData = async() => {
        let startDate=""
        let endDate=""
        if(dateRange.rejectionStartDate.length > 10 || dateRange.rejectionEndDate.length > 10) {
        startDate= dateRange?.rejectionStartDate?.slice(0,10)
        endDate= dateRange?.rejectionEndDate?.slice(0,10)
        }else {
            startDate= dateRange?.rejectionStartDate
            endDate= dateRange?.rejectionEndDate
          }

        setCardLoading(true)
        const reqBody = {
            "vendor": "",
            "start_date": startDate,
            "end_date": endDate,
            "searchTerm": searchValue,
            "filter": {
              "s_journal": rejectingJournal,
              "p_journal": acceptingJournal,
              "p_publisher": publishers,
            },
            "card_type": item.status ? item.status : "published"
          }
          if(accessType){
            reqBody["filter"].oa= true
          }

        axiosService.getCardDetails(reqBody).then(res=> {
            setData(res?.data)
            setCardLoading(false)
        }).catch(error => {
            setCardLoading(false)
            setData({})
            if(error.response.data.message) {
              toast.error(error.response.data.message);
             }else {
              toast.error("Something went wrong!")
             }
        })

    }

    useEffect(()=>{
        if(loading.isApplyFilter|| loading.isClearFilter|| loading.isGo) {
            fetchCardData()
        }
    }, [loading.isApplyFilter, loading.isClearFilter, loading.isGo])

    useEffect(()=> {
      if(dateRange.rejectionStartDate){
        fetchCardData()
      }
    },[isPageReload])

 



  return (
   <Col className='my-2'>
    <Card  className={`h-100 ${item.status!==""? " cardHoverable":""} ${item.status===active && item.status !=="" ? 'focus': ''}` }>
        <Card.Body className="cardStyle">
            <Row className=''>
                {cardLoading? <>
                <Col md={12} className="loaderBox">
                    <SpinnerLoader size='lg'  />
                </Col>
                
                </>:item.status !==""?
                <>
                {item.status === "published" ? <Col md={7} xs={6}>
                    <p className='mb-0'>{item?.title}</p>
                    {data?<><p className='mb-1 titleStyle'>{data?.total || "0"}</p>
                    <p className='mb-1 fs-14'>With us <strong>: {data?.Internal || "0"}</strong></p>
                    <p className='mb-1 fs-14'>Elsewhere <strong>: {data?.External || "0"}</strong></p>
                    <p className='mb-0 fs-14'>Open Access <strong>: {Math.round(data?.oaPercentage) || "0"}%</strong></p>
                    </>
                    : <NoDataFound />
                    }

                </Col>
                :item.status ==="rejected"? <><Col md={7} xs={6}>
                    <p className='mb-0'>{item?.title || "NA"}</p>
                    {data ?<><p className='mb-1 titleStyle'>{data?.total || "0"}</p> 
                    <p className='mb-0'>Rejecting Journals </p>
                  <span className='titleStyle'>{data?.rejectingJournalCount || 0}</span> 
                  </>: <NoDataFound />}
                </Col>
                </>
                :null
                }
                <Col md={5} xs={6} className='mainChartLine ps-0'>
                    {/* <LineChart data={data?.data} /> */}
                    <Line status={item.status} data={data?.data} />
                </Col>
                </>:
                <Col md={12}>
                  <p className='mb-0'>Accepting Journals </p>
                  {data ? <><span className='titleStyle'>{data?.acceptingJournalCount || 0}</span>
                  <p className='mb-0 flex-box'>Accepting Publishers</p>
                  <span className='titleStyle'> {data?.publisherCount || 0} </span>
                  </>: <NoDataFound />}
                </Col>
                }
                
            </Row>
        </Card.Body>
    </Card>
   </Col>   
  )
}

export default CardItem
