import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import classNames from "classnames";

import PopUpTable from "../Table/PopUpTable";

const PopupModule = ({
  showTaleauPopUPTable,
  setShowTaleauPopUPTable,
  handleTableauPopUpTable,
  tableauReportData,
  setTableauReportData,
  isReportTableLoading,
}) => {
  const [isManuscriptModel, setIsManuscriptModel] = useState(false);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const handleClose = () => {
    setIsManuscriptModel(false);
    setShowTaleauPopUPTable(false);
    setTableHeaders([]);
    setTableRows([]);
    setTableauReportData([]);
  };

  useEffect(() => {
    if (showTaleauPopUPTable) {
      setIsManuscriptModel(showTaleauPopUPTable);
      // console.log("tableauReportData => ", tableauReportData);
      tableauReportData?.header?.map((item, index) => {
        setTableHeaders((prevValue) => [...prevValue, item.fieldName]);
      });

      tableauReportData?.data?.map((item, index) => {
        setTableRows((prevValue) => [...prevValue, item]);
      });
    }
  }, [showTaleauPopUPTable, isReportTableLoading]);

  return (
    <Modal show={isManuscriptModel} onHide={() => handleClose()} size="xl">
      <Modal.Body className="p-3" id="top-section">
        <div className="d-flex justify-content-between align-items-start">
          <section className="fw-bold">
            <div>
              <span>Accepting Journal Meta Data</span>
            </div>
          </section>
          <div
            className="text-danger fs-12 cursor-pointer"
            onClick={() => handleClose()}
          >
            <FontAwesomeIcon icon={faXmark} /> Close
          </div>
        </div>
        <hr />
        <PopUpTable
          tableHeaders={tableHeaders}
          tableRows={tableRows}
          isReportTableLoading={isReportTableLoading}
        />
      </Modal.Body>
    </Modal>
  );
};

export default PopupModule;
