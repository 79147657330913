import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { SpinnerLoader } from "../../../Loader/Loader";
const d3ToPng = require("d3-svg-to-png");
//import { d3ToPng } from "d3-svg-to-png";

function Graphdownload({ svg, filename }) {
  const [boolLoader, setBoolLoader] = useState(false);

  function download() {
    var serializer = new XMLSerializer();
    var xmlString = serializer.serializeToString(svg);
    var imgData =
      "data:image/svg+xml;base64," +
      btoa(unescape(encodeURIComponent(xmlString)));
    var link = document.createElement("a");
    link.download = filename + ".svg";
    link.href = imgData;
    link.click();
  }

  function downloadImage(type) {
    setBoolLoader(true);
    d3ToPng(svg, filename, {
      format: type,
      download: true,
      //ignore: '.ignored',
      background: "white",
    }).then((fileData) => {
      //do something with the data
      //console.log(fileData);
      setBoolLoader(false);
    });
  }

  return (
    <div className="d-flex justify-content-end">
      <Dropdown>
        <Dropdown.Toggle variant="light" id="dropdown-basic">
          <FontAwesomeIcon
            icon={faBars}
            size="sm"
            className="fs-14 mx-1 me-3"
          />

          {boolLoader && <SpinnerLoader size="sm" />}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as="button" onClick={() => downloadImage("png")}>
            Download PNG image
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => downloadImage("jpeg")}>
            Download JPEG image
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => downloadImage("webp")}>
            Download WEBP image
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => download()}>
            Download SVG vector image
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default Graphdownload;
