import { ActionType } from "./Types"

export const handleFormChange = (payload) => ({
    type: ActionType.HANDLE_FORM_CHANGE,
    payload: payload
})

export const setDateRange = (object) => ({
    type: ActionType.SET_DATE_RAGE,
    payload: object
})

export const searchDateRenge = (payload) => ({
    type: ActionType.SEARCH_DATE_RANGE,
    payload: payload
})

export const setRejectingJournals = (payload) => ({
    type: ActionType.SET_REJECTING_JOURNALS,
    payload: payload
})

export const setAcceptingJournals = (payload) => ({
    type: ActionType.SET_ACCEPTING_JOURNALS,
    payload: payload
})

export const setPublishers = (payload) => ({
    type: ActionType.SET_PUBLISHERS,
    payload: payload
})

export const setManuscriptGridData = (payload) => ({
    type: ActionType.SET_MANUSCRIPT_GRID_DATA,
    payload: payload
})


export const setTableLoader = (payload) => ({
    type: ActionType.SET_TABLE_LOADER ,
    payload: payload
})

export const setCurrentPage = (payload) => ({
    type: ActionType.SET_CURRENT,
    payload: payload
})

export const setSort = (payload) => ({
    type: ActionType.SET_SORT,
    payload: payload
})

export const setStatus = (payload) => ({
    type: ActionType.SET_STATUS,
    payload: payload
})

export const setAccessType = (payload) => ({
    type: ActionType.SET_ACCESS_TYPE,
    payload: payload
})

export const setManuscriptModal = (payload) => ({
    type: ActionType.SET_MANUSCRIPT_MODAL,
    payload: payload
})

export const setGraphType = (payload) => ({
    type: ActionType.SET_GRAPH_TYPE,
    payload: payload
})

export const setAcceptingJournalsList =(payload) => ({
    type: ActionType.SET_ACCEPTING_JOURNALS_LIST,
    payload: payload
})

export const setRejectingJournalsList =(payload) => ({
    type: ActionType.SET_REJECTING_JOURNALS_LIST,
    payload: payload
})

export const setPublishersList =(payload) => ({
    type: ActionType.SET_PUBLISHERS_LIST,
    payload: payload
})

export const setPageReload = (payload) => ({
    type: ActionType.IS_PAGE_RELOAD,
    payload: payload
})
