import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setDateRange, setManuscriptGridData, setManuscriptModal } from '../../../Redux/Action/filter.action'
import { title, toTitleCase } from "../../Filter/Filter";
import AxiosService from '../../../utils/AxiosService';
import TableGridLayout from '../../TableGridLayout/TableGridLayout';
import SearchFilter from '../../SearchFilter/SearchFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import styles from './ManuscriptDetailsModal.module.scss';
import classNames from 'classnames';

const ManuscriptDetailsModal = () => {
    const {isManuscriptModel, graphType} = useSelector((state)=> state.allFilters)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState({isApplyFilter: false, isClearFilter: false, isGo:false})
    const {manuscriptGridData, searchValue,
        dateRange, status, 
        rejectingJournal,
        acceptingJournal,
        publishers,
        accessType
        } = useSelector((state)=> state.allFilters)

    const [searchQuery, setSearchQuery] = useState("");
    const[reqBody, setReqBody] = useState({})

    const axiosService = new AxiosService()
    const [start, setStart] = useState(0)
    const offset = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [tableLoading, setTableLoading] = useState(false)
    const [sort, setSort] = useState({
        field: "crossref_citations",
        order: "desc"
    })

    const getManuscripts =async (getSearchValue, getCurrentPage) => {
        let startDate=""
        let endDate=""

        if(dateRange.rejectionStartDate.length > 10 || dateRange.rejectionEndDate.length > 10) {
            startDate= dateRange.rejectionStartDate.slice(0,10)
            endDate= dateRange.rejectionEndDate.slice(0,10)
        }else {
            startDate= dateRange.rejectionStartDate
            endDate= dateRange.rejectionEndDate
        }

        setTableLoading(true)
        const start = (getCurrentPage - 1) * offset;
        setStart(start)
        const reqBody = {
            "vendor": "",
            "start_date": startDate,
            "end_date": endDate,
            "status": status,
            "searchTerm": getSearchValue,
            "filter": {
            "s_journal": rejectingJournal,
            "p_journal": acceptingJournal,
            "p_publisher": publishers
            },
            "sort": {
            "field": sort.field,
            "order": sort.order
            },
            "graphFilter": graphType.graphType
            }
            if(accessType){
            reqBody["filter"].oa= true
            }
        axiosService.getSearch(start, offset, reqBody).then(response => {
            if(response.data) {
                setTableLoading(false)
                setLoading({...loading, isApplyFilter:false, isClearFilter: false, isGo:false})
                dispatch(setManuscriptGridData(response.data))
            }
        }).catch(error => {
            setTableLoading(false)
        })
        setReqBody(reqBody)
    }

    useEffect(()=> {
        if(isManuscriptModel){
            setCurrentPage(1)
            setStart(0)

            getManuscripts(searchQuery, 1)
        }
    }, [sort])

    useEffect(()=> {
        setSearchQuery(searchValue);
        if(isManuscriptModel || loading.isGo){
            setSort({
                field: "crossref_citations",
                order: "desc"
            })
            getManuscripts(searchValue, currentPage)
        }
    }, [isManuscriptModel, loading.isGo])

    const handleClose = () => {
        dispatch(setManuscriptModal(false))
        setCurrentPage(1)
    }

    const modalSearch = (getSearchValue) => {
        setCurrentPage(1)
        setSearchQuery(getSearchValue);
        getManuscripts(getSearchValue, 1)
    }

    const setPagination = (page) => {
        setCurrentPage(page);
        getManuscripts(searchQuery, page)
    }
    
    return (
        <Modal show={isManuscriptModel} onHide={()=> handleClose()} size='xl' dialogClassName={styles.customeModalStyle}>
        <Modal.Body className='p-3' id="top-section">
            <div className='d-flex justify-content-between align-items-start'>
                <section className={classNames(styles.width90,'fw-bold')}>
                    <div>
                        <span>{toTitleCase(title)} {">"} </span> 
                        <span className="linkText">{graphType?.title || "NA"}</span>
                    </div>
                </section>
                <div className='text-danger fs-12 cursor-pointer' onClick={()=> handleClose()}><FontAwesomeIcon icon={faXmark} /> Close</div>
            </div>
            <SearchFilter
                loading={loading}
                setLoading={setLoading}
                current={currentPage}
                setCurrent={setCurrentPage}
                inputSearchValue={searchQuery}
                applyModalSearch={modalSearch}
            />
            <hr />
            <TableGridLayout data={manuscriptGridData} 
            start={start} setStart={setStart} 
            offset={offset} 
            gridTitle={"Published Manuscripts"}
            current={currentPage}
            setCurrent={setPagination}
            loading={tableLoading}
            setLoading={setTableLoading}
            classes={"border-0 p-0"}
            sort={sort}
            setSort={setSort}
            isModal={true}
            reqBody={reqBody}
            gridType="manuscriptGrid"
            />
        </Modal.Body>
        </Modal>
    )
}

export default ManuscriptDetailsModal
