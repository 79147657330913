import { Table, Row, Col, Card } from "react-bootstrap";
import TableLoader from "../../Table/TableLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../Pagination/Pagination";
import { useState } from "react";

const PopUpTable = ({ tableHeaders, tableRows, isReportTableLoading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const offset = 10;
  const totalCount = tableRows?.length;
  const onPageChange = (page) => {
    if (currentPage) {
      setCurrentPage(page);
    }
    // else {
    //   dispatch(setCurrentPage(page));
    // }
  };

  return (
    <>
      <Row>
        <Col className="sm-flex-column-contaniner d-flex justify-content-end align-items-start mb-2"></Col>
      </Row>
      {isReportTableLoading && (
        <TableLoader headLength={1} dataLength={offset} />
      )}
      {!isReportTableLoading && (
        <>
          <Pagination
            onPageChange={(page) => {
              onPageChange(page);
            }}
            totalCount={totalCount}
            currentPage={currentPage}
            pageSize={offset}
            className="justify-content-end"
          />
          <Table
            responsive
            className="tableBlock table-bordered table-hover  mb-0"
          >
            <thead className="text-uppercase">
              <tr className="align-middle" key={"No"}>
                <th width="7%">No.</th>
                {tableHeaders?.map((h, idx) => (
                  <th key={h + idx}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableRows.length > 0 ? (
                tableRows?.map((colData, idx) => {
                  if (
                    idx >= (currentPage - 1) * offset &&
                    idx < (currentPage - 1) * offset + offset
                  ) {
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        {colData.map((item, index) => {
                          return (
                            <td key={item + index}>{item.value.toString()}</td>
                          );
                        })}
                      </tr>
                    );
                  }
                })
              ) : (
                <tr>
                  <td colSpan={9}>
                    <div className="text-center">
                      <h6>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="text-info me-1"
                        />
                        No data found
                      </h6>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default PopUpTable;
