import { useEffect, useState } from "react";
import AxiosService from "../../../utils/AxiosService";
import { useDispatch, useSelector } from "react-redux";
import TableItem from "./JournalTableDatatem";
// import TableLoader from "../TableLoader"
import TableLoader from "../../Table/TableLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
// import ExpandableTableDetails from "../ExpandableTableGrid/ExpandableTableGrid"
import ExpandableTableDetails from "../../Table/ExpandableTableGrid/ExpandableTableGrid";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";
import { title, toTitleCase } from "../../Filter/Filter";
import ReactGA from "react-ga4";

const ExpandableRow = ({
  headers,
  hasSubHeader,
  data,
  idx,
  gridTitle,
  start,
  parentData,
  setParentRowData,
  graphType,
}) => {
  const [expandableGridData, setExpandableGridData] = useState({});
  const [expandableGridLoader, setExpandableGridLoader] = useState(false);
  const [current, setCurrent] = useState(0);
  const axiosService = new AxiosService();
  const [rowItem, setRowItem] = useState({});
  const [reqBody, setReqBody] = useState({});
  const [expandableGridTitle, setGridTitle] = useState("");

  const {
    journalType,
    dateRange,
    searchValue,
    currentPage,
    acceptingJournal,
    rejectingJournal,
    publishers,
    accessType,
  } = useSelector((state) => state.allFilters);

  const [expand, setExpand] = useState(false);
  const [total, setTotal] = useState(0);
  const offset = 10;

  const getExpandableTableDetails = async () => {
    let startDate = "";
    let endDate = "";

    if (
      dateRange.rejectionStartDate.length > 10 ||
      dateRange.rejectionEndDate.length > 10
    ) {
      startDate = dateRange.rejectionStartDate.slice(0, 10);
      endDate = dateRange.rejectionEndDate.slice(0, 10);
    } else {
      startDate = dateRange.rejectionStartDate;
      endDate = dateRange.rejectionEndDate;
    }

    const reqBody = {
      vendor: "",
      start_date: startDate,
      end_date: endDate,
      status: "resolved",
      searchTerm: searchValue,
      filter: {
        s_journal: data?.s_journal ? [data?.s_journal] : rejectingJournal,
        p_journal: data?.p_journal ? [data?.p_journal] : acceptingJournal,
        p_publisher: data?.p_publisher ? [data.p_publisher] : publishers,
      },
      sort: {
        field: "total",
        order: "desc",
      },
    };
    if (accessType) {
      reqBody["filter"].oa = true;
    }

    setReqBody(reqBody);
    setExpandableGridLoader(true);
    if (gridTitle === "Publishers") {
      setGridTitle("publisherExpandableGrid");
      axiosService
        .getPublisherExpandableGridDetails(current, 10, reqBody)
        .then((res) => {
          setExpandableGridData(res?.data);
          setTotal(res?.data?.total);
          setExpandableGridLoader(false);
        })
        .catch((error) => {
          setExpandableGridData({});
          setExpandableGridLoader(false);
        });
    } else if (
      gridTitle === "Journals by Publication Count" &&
      graphType === "cascadeGraph"
    ) {
      setGridTitle("journalCascadeExpandableGrid");
      axiosService
        .getJournalCascadeExpandableGrid(current, 10, reqBody)
        .then((res) => {
          setExpandableGridData(res?.data);
          setTotal(res?.data?.total);
          setExpandableGridLoader(false);
        })
        .catch((error) => {
          setExpandableGridData({});
          setExpandableGridLoader(false);
        });
    } else if (
      gridTitle === "Journals by Publication Count" &&
      graphType === "categoriesGraph"
    ) {
      setGridTitle("categoriesExpandableGrid");
      axiosService
        .getCategoriesExpandableGrid(current, 10, reqBody)
        .then((res) => {
          setExpandableGridData(res?.data);
          setTotal(res?.data?.total);
          setExpandableGridLoader(false);
        })
        .catch((error) => {
          setExpandableGridData({});
          setExpandableGridLoader(false);
        });
    }
  };

  const handleNext = () => {
    setCurrent(current + offset);
  };

  const handlePrev = () => {
    setCurrent(current - offset);
  };

  const handleExportAll = () => {
    ReactGA.event({
      category: toTitleCase(title),
      action: `Export All Records`,
      label: `Expandable_title= ${expandableGridTitle}, total_records=${total}`,
    });
    toast.success("File download started...");
    axiosService
      .exportAllRecords(expandableGridTitle, reqBody)
      .then((res) => {
        fileDownload(res.data, expandableGridTitle + "_all_records.xlsx");
        toast.success("File downloaded successfully!");
      })
      .catch((error) => {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  useEffect(() => {
    if (expand) {
      getExpandableTableDetails();
    }
  }, [current, expand]);

  return (
    <>
      <tr className="align-middle" key={idx}>
        <td className="py-3" key={idx}>
          <span className="idxStyle">{start + idx + 1}</span>
        </td>
        {
          hasSubHeader &&
            headers?.map((h, idx) => {
              if (!h.isSubHeader) {
                return (
                  <TableItem
                    rowData={data}
                    headerDetails={h}
                    dataKey={idx}
                    headers={headers}
                    gridTitle={gridTitle}
                    start={start}
                    setExpandableGridData={setExpandableGridData}
                    getExpandableTableDetails={getExpandableTableDetails}
                    setExpand={setExpand}
                    setCurrent={setCurrent}
                    expand={expand}
                    parentData={parentData}
                    setParrentData={setRowItem}
                    graphType={graphType}
                  />
                );
              }

              return h.subHeaderDisplay.map((sh, idx) => {
                // setParentRowData(data)
                return (
                  <TableItem
                    rowData={data[h.field]}
                    headerDetails={sh}
                    dataKey={idx}
                    headers={h.subHeaderDisplay}
                    gridTitle={gridTitle}
                    start={start}
                    setExpandableGridData={setExpandableGridData}
                    getExpandableTableDetails={getExpandableTableDetails}
                    setExpand={setExpand}
                    setCurrent={setCurrent}
                    expand={expand}
                    parentData={parentData}
                    parentRowData={data}
                    setParrentData={setRowItem}
                    graphType={graphType}
                  />
                );
              });
            })
          // : headers?.map((h, idx)=>(
          // <TableItem rowData={data} headerDetails={h} dataKey={idx} headers={headers}
          // gridTitle={gridTitle} start={start}
          // setExpandableGridData={setExpandableGridData}
          // getExpandableTableDetails={getExpandableTableDetails}
          // setExpand={setExpand}
          // setCurrent={setCurrent}
          // expand={expand}
          // parentData={parentData}
          // setParrentData={setRowItem}
          // graphType={graphType}
          // />
          // ))
        }
      </tr>
      {expand ? (
        expandableGridLoader ? (
          <tr>
            <td colSpan={1}></td>
            <td colSpan={5}>
              {" "}
              <TableLoader headLength={4} dataLength={10} />
            </td>
          </tr>
        ) : expandableGridData ? (
          <>
            <tr className="expandableTable mt-3">
              <td colSpan={1}></td>
              <td colSpan={5}>
                <div className="d-flex justify-content-end">
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn border btn-sm px-3"
                      onClick={() => handleExportAll()}
                    >
                      <FontAwesomeIcon icon={faFileExport} className="file" />{" "}
                      Export All
                    </button>
                  </div>

                  {total > 10 && (
                    <div className="ms-3 d-flex justify-content-end">
                      {
                        <button
                          onClick={() => handlePrev()}
                          disabled={current === 0}
                          className={`border-0 bg-transparent me-2 ${
                            current === 0 ? "text-disabled" : "linkText"
                          }`}
                        >
                          <FontAwesomeIcon icon={faAngleDoubleLeft} /> Prev
                        </button>
                      }
                      {
                        <button
                          onClick={() => handleNext()}
                          disabled={
                            current + expandableGridData?.data?.length === total
                          }
                          className={`border-0 bg-transparent ${
                            current + expandableGridData?.data?.length === total
                              ? "text-disabled"
                              : "linkText"
                          }`}
                        >
                          Next <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </button>
                      }
                    </div>
                  )}
                </div>

                <ExpandableTableDetails
                  tableData={expandableGridData?.data}
                  headers={expandableGridData?.config}
                  parent={rowItem}
                  start={current}
                  graphType={graphType}
                />
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr className="expandableTable mt-3">
              <td colSpan={1}></td>
              <td colSpan={5}>
                <div className="text-center">No data found</div>
              </td>
            </tr>
          </>
        )
      ) : null}
    </>
  );
};

export default ExpandableRow;
