import {combineReducers} from "redux"
import { FilterReducer } from "./Filter.reducer"
import { journalReducer } from "./Joournal.reducer"
import loginReducer from "./Login.reducer"
import tokenReducer from "./Token.reducer"

const reducer = combineReducers({
    allFilters: FilterReducer ,
    journal: journalReducer,
    login: loginReducer,
    token: tokenReducer
})

export default reducer