import React, { useEffect } from 'react';
import * as d3 from 'd3';

function loadGraph(data, status){
    // set the dimensions and margins of the graph;

    var element = d3.select('.mainChartLine').node();
    const compWidth = element.getBoundingClientRect().width;

    const margin = {top: 40, right: 10, bottom: 10, left: 10},
    width = compWidth - margin.left - margin.right,
    height = 160 - margin.top - margin.bottom;

    if(!d3.select('#line' + status + '> svg').empty()){
        d3.select('#line' + status + '> svg').remove()
    }

    // append the svg object to the body of the page
    let svg = d3.select("#line"+status)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    // Add X axis --> it is a date format
    const x = d3.scaleTime()
      .domain([0, data.length])
      .range([ 0, width ]);
    
    const xAxis = svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    xAxis.selectAll('path,line,text').remove();

    // Add Y axis
    const y = d3.scaleLinear()
      .domain([0, d3.max(data, function(d) { return d; })])
      .range([ height, 0 ]);
    
    const yAxis = svg.append("g")
      .call(d3.axisLeft(y));

    yAxis.selectAll('path,line,text').remove();

    // Add the area
    svg.append("path")
      .datum(data)
      .attr("fill", "#9c50ad")
      .attr("fill-opacity", 0.3)
      .attr("stroke", "#9c50ad")
      .attr("stroke-width", 1.5)
      .attr("d", d3.area().curve(d3.curveMonotoneX)
        .x(function(d, i) { return x(i) })
        .y0(y(0))
        .y1(function(d) { return y(d) })
    )

}

function Line(props){

    const status = props.status;

    useEffect(() => {

        if(props.data && props.data.length >= 2){
          loadGraph(props.data, status);
        }
    }, [JSON.stringify(props.data), status])

    return <div id={"line" + status}></div>
}

export default Line;