import React from "react";
import { Table } from "react-bootstrap";
import "./JournalTableDetailsDoubleHeader.scss";
import TableHeadItem from "./JournalTableHeadItem";
import ExpandableRow from "./ExpandableTableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const JournalTableDetailsDoubleHeader = ({
  tableData,
  headers,
  hasSubHeader,
  start,
  handleSort,
  gridTitle,
  sort,
  parentData,
  setParentRowData,
  graphType,
}) => {
  return (
    <>
      <Table responsive className="tableBlock table-bordered table-hover  mb-0">
        <thead className="text-uppercase text-center">
          <tr className="align-middle" key={"No"}>
            <th width="7%" rowSpan={hasSubHeader ? 2 : 1}>
              No.
            </th>
            {
              // hasSubHeader
              //   ?
              headers?.map((h, idx) => {
                let rowSpan = 2;
                let colSpan = 1;
                if (h.isSubHeader) {
                  rowSpan = 1;
                  colSpan = h.subHeaderNumber;
                }

                return (
                  <TableHeadItem
                    headingData={h}
                    rowSpan={rowSpan}
                    colSpan={colSpan}
                    dataKey={idx}
                    handleChange={handleSort}
                    sort={sort}
                  />
                );
              })
              // : headers?.map((h, idx) => (
              //     <TableHeadItem
              //       headingData={h}
              //       dataKey={idx}
              //       handleChange={handleSort}
              //       sort={sort}
              //     />
              //   ))
            }
          </tr>
          {hasSubHeader && (
            <tr>
              {headers?.map((h, idx) => {
                return h.subHeaderDisplay.map((item, index) => {
                  return (
                    <TableHeadItem
                      headingData={item}
                      dataKey={index}
                      handleChange={handleSort}
                      sort={sort}
                    />
                  );
                });
              })}
            </tr>
          )}
        </thead>
        {/* text-center */}
        <tbody className="">
          {tableData.length > 0 ? (
            hasSubHeader &&
            tableData?.map((data, idx) => {
              return (
                <ExpandableRow
                  data={data}
                  hasSubHeader={hasSubHeader}
                  idx={idx}
                  headers={headers}
                  start={start}
                  gridTitle={gridTitle}
                  parentData={parentData}
                  setParentRowData={setParentRowData}
                  graphType={graphType}
                />
              );
            })
          ) : (
            // : (
            //   tableData?.map((data, idx) => {
            //     return (
            //       <ExpandableRow
            //         data={data}
            //         idx={idx}
            //         headers={headers}
            //         start={start}
            //         gridTitle={gridTitle}
            //         parentData={parentData}
            //         setParentRowData={setParentRowData}
            //         graphType={graphType}
            //       />
            //     );
            //   })
            // )
            <tr>
              <td colSpan={9}>
                <div className="text-center">
                  <h6>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="text-info me-1"
                    />
                    No data found
                  </h6>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default JournalTableDetailsDoubleHeader;
