import React from 'react'
import { Spinner } from 'react-bootstrap'

export const SpinnerLoader = ({size="sm", classes}) => {
    return (
        <Spinner animation="border" size={size} className={classes} />
    )
  }

export const ButtonLoader = ({classes}) => {
    return (
    <button className={classes}>Loading 
    <Spinner animation="grow" size='sm' />
    </button>)
}
