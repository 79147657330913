import React from "react";
import { Table } from "react-bootstrap";
import "./JournalTableDetails.scss";
import TableHeadItem from "./JournalTableHeadItem";
import ExpandableRow from "./ExpandableTableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const JournalTableDetails = ({
  tableData,
  headers,
  start,
  handleSort,
  gridTitle,
  sort,
  parentData,
  setParentRowData,
  graphType
}) => {
  return (
    <>
      <Table responsive className="tableBlock table-bordered table-hover  mb-0">
        <thead className="text-uppercase">
          <tr className="align-middle" key={"No"}>
            <th width="7%">No.</th>
            {headers?.map((h, idx) => (
              <TableHeadItem
                headingData={h}
                dataKey={idx}
                handleChange={handleSort}
                sort={sort}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.length > 0 ? (
            tableData?.map((data, idx) => {
              return (
                <ExpandableRow
                  data={data}
                  idx={idx}
                  headers={headers}
                  start={start}
                  gridTitle={gridTitle}
                  parentData={parentData}
                  setParentRowData={setParentRowData}
                  graphType={graphType}
                />
              );
            })
          ) : (
            <tr>
              <td colSpan={9}>
                <div className="text-center">
                  <h6>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="text-info me-1"
                    />
                    No data found
                  </h6>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default JournalTableDetails;
