import React, { useState } from "react";
import ModalDetails from "../../Modals/ModalDetails";
import openAccessLogo from "../../../assets/655px-Open_Access_logo.png";
import "./TableDetails.scss"
import { title, toTitleCase } from "../../Filter/Filter";
import ReactGA from "react-ga4"

const TableItem = ({ rowData, headerDetails, dataKey, headers }) => {
  const [showManuscriptDetails, setShowManuscriptDetails] = useState(false);

  const handleClose = () => {
    setShowManuscriptDetails(false);
  };

  const handleOpenModal = () => {
    ReactGA.event({
      category: toTitleCase(title),
      action: `Manuscript Details Modal Clicked`,
      label: `s_title= "${rowData[headerDetails?.field]}"`
    })
    setShowManuscriptDetails(true);
  };

  return (
    <>
      <td key={dataKey}>
        {headerDetails?.dataType === "url" &&
        headerDetails.field === "p_doi" ? (
          <>
            <a
              href={rowData[headerDetails.field] &&`https://doi.org/${rowData[headerDetails.field]}`}
              target="_blank"
              className={rowData[headerDetails.field]? "linkText": "text-secondary"+ " flex-box"}
            >
              <span className="text-break text-break-sm">
                <span>{rowData[headerDetails.field] }</span>
              </span>
            </a>{" "}
          </>
        ) : (headerDetails.field === "s_title") ? (
          <div className="linkText flex-box">
            <span onClick={handleOpenModal} className="cursor-pointer">
              {rowData["p_access_type"] === "OA" ? (
                <img
                  width="10px"
                  height="15px"
                  className="me-1 my-1 text-muted"
                  src={openAccessLogo}
                  alt="Open access logo"
                />
              ) : null}

              {rowData[headerDetails.field] || "NA"}
            </span>
          </div>
        ) : headerDetails.display === "REJECT TYPE" ? (
          <div className="text-capitalize">{rowData[headerDetails.field]}</div>
        ) : (headerDetails.dataType ==="url") ? (
          <a target="_blank" href={rowData[headerDetails.field]}>
            {rowData[headerDetails.field]}
          </a>
        ): (
          <div>{rowData[headerDetails.field]}</div>
        )}
      </td>

      {showManuscriptDetails && <ModalDetails
        show={showManuscriptDetails}
        handleClose={handleClose}
        data={rowData}
        headers={headers}
      />}
    </>
  );
};

export default TableItem;
