import React, { useEffect } from "react";
import MultiLineForAverageCitation from "../../components/graphs/D3/MultiLineForAverageCitation/MultiLineForAverageCitation";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Filter, { handleGAEvent } from "../../components/Filter/Filter";
import AxiosService from "../../utils/AxiosService";
import { setDateRange, setTableLoader } from "../../Redux/Action/filter.action";

import TableGridLayout from "../../components/TableGridLayout/TableGridLayout";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const InsightsByCitations = () => {
  const { currentPage, isPageReload } = useSelector(
    (state) => state.allFilters
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosService = new AxiosService();
  const [loading, setLoading] = useState({
    isApplyFilter: false,
    isClearFilter: false,
    isGo: false,
  });
  const [graphLoader, setGraphLoader] = useState(false);
  const [reqBody, setReqBody] = useState({});

  const [chartData, setChartData] = useState({});
  const [gridData, setGridData] = useState({});
  const [sort, setSort] = useState({
    field: "totalPublication",
    order: "desc",
  });
  const [start, setStart] = useState(0);
  const offset = 10;

  const getAllRecords = async () => {
    dispatch(setTableLoader(true));
    setGraphLoader(true);
    const start = (currentPage - 1) * offset;
    setStart(start);
    const reqBody = {
      s_journal: [],
      year: [],
      citationInterval: "year",
    };

    axiosService
      .getAverageCitationChart(start, offset, reqBody)
      .then((response) => {
        if (response.data) {
          setGraphLoader(false);
          setLoading({
            ...loading,
            isApplyFilter: false,
            isClearFilter: false,
            isGo: false,
          });
          setChartData(response.data);
        }
      })
      .catch((error) => {
        setGraphLoader(false);
        setChartData({});
      });
    axiosService
      .getAverageCitationGrid(start, offset, reqBody)
      .then((response) => {
        if (response?.data) {
          dispatch(setTableLoader(false));
          setLoading({
            ...loading,
            isApplyFilter: false,
            isClearFilter: false,
            isGo: false,
          });

          setGridData(response?.data);
        }
      })
      .catch((error) => {
        dispatch(setTableLoader(false));
        setGridData({});
      });
    setReqBody(reqBody);
    handleGAEvent(loading, reqBody);
  };

  useEffect(() => {
    getAllRecords();
  }, [currentPage, sort, isPageReload]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  function backToLink() {
    navigate("/cumulative-analytics");
  }

  return (
    <div className="pt-3">
      {/* <Row className="d-none">

    <Filter
      loading={loading}
      // setLoading={setLoading}
      // getAllRecords={getAllRecords}
    />
</Row> */}
      <Row>
        <Col xl={6}>
          <h5 className="">
            {
              <>
                <span
                  className="linkText cursor-pointer noUnderline"
                  onClick={backToLink}
                >
                  Cumulative Analytics{" "}
                </span>
                {">"}
              </>
            }
            {"Insights by Average Citation"}
            {/* {toTitleCase(title)} */}
          </h5>
        </Col>
      </Row>

      <MultiLineForAverageCitation
        data={chartData}
        loader={graphLoader}
        setLoader={setGraphLoader}
        loading={loading}
      />

      {Object.keys(gridData).length ? (
        <TableGridLayout
          data={gridData}
          start={start}
          setStart={setStart}
          offset={offset}
          gridTitle={"Journals"}
          getAllRecords={getAllRecords}
          sort={sort}
          setSort={setSort}
          reqBody={reqBody}
          gridType="averageCitationGrid"
        />
      ) : null}
    </div>
  );
};

// export default TimesToPublication

export default InsightsByCitations;
